import React, { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import Spinner from 'react-bootstrap/Spinner';
import { IoMdClose } from "react-icons/io";
import { CiCircleInfo } from "react-icons/ci";
import { makeApiRequest } from "../../config/AxiosService";
import moment from "moment"
import config from "../../config/Config"
import CountdownTimer from "../separate/Timer";

function Transactionhistory() {

    const [depositData, setDepositData] = useState([]);
    const [withdrawData, setWithdrawData] = useState([]);
    const [currentDepositPage, setCurrentDepositPage] = useState(1);
    const [currentWithdrawPage, setCurrentWithdrawPage] = useState(1);
    const [activeTab, setActiveTab] = useState('deposit');
    const itemsPerPage = 5;


    const getdepositHistory = async () => {
        try {
            let params = {
                url: `depositHistory`,
                method: 'GET',
            };
            let response = await makeApiRequest(params);
            if (response.status == true) {
                const UserDeposit = response.data;
                setDepositData(UserDeposit);
            } else {
                setDepositData('');
            }
        } catch (error) {
            console.log('error', error);
        }
    };

    const getwithdrawHistory = async () => {
        try {
            let params = {
                url: `withdrawHistory`,
                method: 'GET',
            };
            let response = await makeApiRequest(params);
            if (response.status == true) {
                const UserWithdraw = response.data;
                setWithdrawData(UserWithdraw);
            } else {
                setWithdrawData('');
            }
        } catch (error) {
            console.log('error', error);
        }
    };


    useEffect(() => {
        getdepositHistory();
        getwithdrawHistory();
    }, []);

    const getCurrentRecords = () => {
        const data = activeTab === 'deposit' ? depositData : withdrawData;
        const currentPage = activeTab === 'deposit' ? currentDepositPage : currentWithdrawPage;
        const indexOfLastRecord = currentPage * itemsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - itemsPerPage;
        return data.slice(indexOfFirstRecord, indexOfLastRecord);
    };

    const getTotalPages = () => {
        const data = activeTab === 'deposit' ? depositData : withdrawData;
        return Math.ceil(data.length / itemsPerPage);
    };

    const handleNextPage = () => {
        const totalPages = getTotalPages();
        if (activeTab === 'deposit' && currentDepositPage < totalPages) {
            setCurrentDepositPage(currentDepositPage + 1);
        } else if (activeTab === 'withdraw' && currentWithdrawPage < totalPages) {
            setCurrentWithdrawPage(currentWithdrawPage + 1);
        }
    };

    const handlePrevPage = () => {
        if (activeTab === 'deposit' && currentDepositPage > 1) {
            setCurrentDepositPage(currentDepositPage - 1);
        } else if (activeTab === 'withdraw' && currentWithdrawPage > 1) {
            setCurrentWithdrawPage(currentWithdrawPage - 1);
        }
    };

    const currentRecords = getCurrentRecords();
    const totalPages = getTotalPages();
    const currentPage = activeTab === 'deposit' ? currentDepositPage : currentWithdrawPage;



    const compressString = (str) => {
        if (!str) {
            return '';
        }
        return str.length > 10 ? `${str.slice(0, 6)}...${str.slice(-4)}` : str;
    };

    return (
        <div className="App">
             <ToastContainer /> 
            <div className="container-fluid" style={{ marginLeft: "15px" }}>
                <div className="custom-saled-order text-dark">
                    <div className="row">
                        <div className="col-sm-12">

                            <div className="py-3 mt-lg-3 justify-content-center d-flex justify-content-between">
                                <h3 className=" component-user  text-center ms-lg-0">Transaction</h3>
                            </div>
                            <div className="custom-inside-saled-order text-center mt-0">
                                <ul
                                    class="nav nav-pills mb-3 me-lg-0 text-center  justify-content-lg-end align-items-center gap-4 gap-lg-0 justify-content-end"
                                    id="pills-tab"
                                    role="tablist"
                                >

                                    <li class="nav-item" role="presentation">
                                        <button
                                            class={`nav-link register-btn-2 me-4 ${activeTab === 'deposit' ? 'active' : ''}`}
                                            id="pills-home-tab"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pills-home"
                                            type="button"
                                            role="tab"
                                            aria-controls="pills-home"
                                            aria-selected="true"
                                            onClick={() => setActiveTab('deposit')}
                                        >
                                            Deposit
                                        </button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button
                                            class={`nav-link register-btn-2 ${activeTab === 'withdraw' ? 'active' : ''}`}
                                            id="pills-profile-tab"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pills-profile"
                                            type="button"
                                            role="tab"
                                            aria-controls="pills-profile"
                                            aria-selected="true"
                                            onClick={() => setActiveTab('withdraw')}

                                        >
                                            Withdraw
                                        </button>
                                    </li>
                                
                                </ul>
                                <div class="tab-content" id="pills-tabContent">
                                    <div
                                        class={`tab-pane fade ${activeTab === 'deposit' ? 'show active' : ''}`}
                                        id="pills-home"
                                        role="tabpanel"
                                        aria-labelledby="pills-home-tab"
                                        tabindex="0"
                                    >
                                        <div className="custom-datascroll">
                                            <table className="table w-100 mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>S.No</th>
                                                        <th>Email</th>
                                                        <th>FromAddress</th>
                                                        <th>TransactionHash</th>
                                                        <th>Transaction Amount</th>
                                                        <th>Network</th>
                                                        <th>Date/Time</th>
                                                        <th>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {currentRecords.length > 0 ? (
                                                        currentRecords.map((datas, index) => (
                                                            <tr key={index}>
                                                                <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                                                <td>{datas.email}</td>
                                                                <td>{compressString(datas.fromAddress)}</td>
                                                                <td>
                                                                    {datas.network === "BNB" && datas.latestTxhash ? (
                                                                        <a
                                                                            href={`${config.BNB_URL}/tx/${datas.latestTxhash}`}
                                                                            target="_blank"
                                                                        >
                                                                            {compressString(datas.latestTxhash)}
                                                                        </a>
                                                                    ) : datas.network === "TRX" && datas.latestTxhash ? (
                                                                        <a
                                                                            href={`${config.TRX_Explorer_URL}/transaction/${datas.latestTxhash}`}
                                                                            target="_blank"
                                                                            className="text-color"
                                                                        >
                                                                            {compressString(datas.latestTxhash)}
                                                                        </a>
                                                                    ) : datas.network === "BTC" && datas.latestTxhashh ? (
                                                                        <a
                                                                            href={`${config.BTC_URL}/tx/${datas.latestTxhash}`}
                                                                            target="_blank"
                                                                            className="text-dark"
                                                                        >
                                                                            {compressString(datas.latestTxhash)}
                                                                        </a>
                                                                    ) : null}
                                                                </td>
                                                                <td>{datas.latestTransactionAmount}</td>
                                                                <td>{datas.network}</td>
                                                                <td><CountdownTimer initialCountdown={datas.countdown} /></td>
                                                                <td>{ datas.status == 1 ? "Withdraw the Amount" : "Cannot Withdraw" }</td>
                                                            </tr>
                                                        ))
                                                    ) : (

                                                        <tr>
                                                            <td colSpan="10" className="text-center">
                                                                No Data
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                        <nav>
                                            <ul className="pagination justify-content-center">
                                                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                                    <button className="page-link" onClick={handlePrevPage}>
                                                        Previous
                                                    </button>
                                                </li>
                                                <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                                                    <button className="page-link" onClick={handleNextPage}>
                                                        Next
                                                    </button>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                    <div
                                        class={`tab-pane fade ${activeTab === 'withdraw' ? 'show active' : ''}`}
                                        id="pills-profile"
                                        role="tabpanel"
                                        aria-labelledby="pills-profile-tab"
                                        tabindex="0"
                                    >
                                        <div className="custom-datascroll">
                                            <table class="table w-100 mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>S.No</th>
                                                        <th>Email</th>
                                                        <th>FromAddress</th>
                                                        <th>ToAddress</th>
                                                        <th>TransactionHash</th>
                                                        <th>Transaction Amount</th>
                                                        <th>Network</th>
                                                        <th>Date/Time </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {currentRecords.length > 0 ? (
                                                        currentRecords.map((data, index) => (
                                                            <tr>
                                                               <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                                                <td>{data.email}</td>
                                                                <td>{compressString(data.fromAddress)}</td>
                                                                <td>{compressString(data.toAddress)}</td>
                                                                <td>
                                                                    {data.coin === "BNB" && data.txhash ? (
                                                                        <a
                                                                            href={`${config.BNB_URL}/tx/${data.txhash}`}
                                                                            target="_blank"
                                                                        >
                                                                            {compressString(data.txhash)}
                                                                        </a>
                                                                    ) : data.coin === "TRX" && data.txhash ? (
                                                                        <a
                                                                            href={`${config.TRX_Explorer_URL}/transaction/${data.txhash}`}
                                                                            target="_blank"
                                                                            className="text-color"
                                                                        >
                                                                            {compressString(data.txhash)}
                                                                        </a>
                                                                    ) : data.coin === "BTC" && data.txhash ? (
                                                                        <a
                                                                            href={`${config.BTC_URL}/tx/${data.txhash}`}
                                                                            target="_blank"
                                                                            className="text-dark"
                                                                        >
                                                                            {compressString(data.txhash)}
                                                                        </a>
                                                                    ) : null}
                                                                </td>
                                                                <td>{data.amount}</td>
                                                                <td>{data.coin}</td>
                                                                <td>{moment(data.date).format('MMMM Do YYYY, h:mm:ss a')}</td>
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <tr>
                                                            <td colSpan="9" className="text-center">
                                                                No Orders
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                        <nav>
                                            <ul className="pagination justify-content-center">
                                                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                                    <button className="page-link" onClick={handlePrevPage}>
                                                        Previous
                                                    </button>
                                                </li>
                                                <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                                                    <button className="page-link" onClick={handleNextPage}>
                                                        Next
                                                    </button>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >

    );
}


export default Transactionhistory;