import { BrowserRouter, Routes, Route, useParams } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import "../src/Assets/Css/Style.css";
import Navbar from "./pages/separate/Navbar";
import Footer from "./pages/separate/Footer";
import Error from "./pages/Error";
import { useState } from "react";
import Login from "./pages/Login";
import "./Assets/Css/Styleone.css";
import Register from "./pages/Register";
import Dashboard from "./pages/Dashboard";
import ProtectedRoute from "./pages/Auth/ProtetctRoute";
import ForgotPassword from "./pages/Forgotpassword";
import ResetPassword from "./pages/Resetpassword";

function App() {
  const [showNavbar, setShowNavbar] = useState(true);

  return (
    <>
      <BrowserRouter>
        {showNavbar && <Navbar />}
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route
            exact
            path="/login"
            element={<Login setShowNavbar={setShowNavbar} />}
          />
          <Route
            exact
            path="/forgot-password"
            element={<ForgotPassword setShowNavbar={setShowNavbar} />}
          />
          <Route
            exact
            path="/userforgot-password"
            element={<ForgotPassword setShowNavbar={setShowNavbar} />}
          />

          <Route
            exact
            path="/reset-password"
            element={<ResetPassword setShowNavbar={setShowNavbar} />}
          />
          <Route
            exact
            path="/userreset-password/:email/:token"
            element={<ResetPassword setShowNavbar={setShowNavbar} />}
          />

          <Route
            exact
            path="/register"
            element={<Register setShowNavbar={setShowNavbar} />}
          />
          <Route
            exact
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Dashboard setShowNavbar={setShowNavbar} />
              </ProtectedRoute>
            }
          />
        
          <Route
            exact
            path="/dashboard/withdraw"
            element={
              <ProtectedRoute>
                <Dashboard setShowNavbar={setShowNavbar} />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/dashboard/deposit"
            element={
              <ProtectedRoute>
                <Dashboard setShowNavbar={setShowNavbar} />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/dashboard/profile"
            element={
              <ProtectedRoute>
                <Dashboard setShowNavbar={setShowNavbar} />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/dashboard/history"
            element={
              <ProtectedRoute>
                <Dashboard setShowNavbar={setShowNavbar} />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/dashboard/reward-history"
            element={
              <ProtectedRoute>
                <Dashboard setShowNavbar={setShowNavbar} />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/dashboard/reward-data/:id"
            element={
              <ProtectedRoute>
                <Dashboard setShowNavbar={setShowNavbar} />
              </ProtectedRoute>
            }
          />
         
          <Route
            exact
            path="/dashboard/referred"
            element={
              <ProtectedRoute>
                <Dashboard setShowNavbar={setShowNavbar} />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="*"
            element={<Error setShowNavbar={setShowNavbar} />}
          />
        </Routes>
        {showNavbar && <Footer />}
      </BrowserRouter>
    </>
  );
}

export default App;
