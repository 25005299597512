import React from "react";
import { useEffect, useState } from "react";
// import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { eye } from "react-icons-kit/feather/eye";
import { Icon } from "react-icons-kit";
import { Spinner } from "react-bootstrap";
import { makeApiRequest } from "../config/AxiosService";
import profile3 from "../Assets/images/login-signup/profile3.png";
import darklogo from "../Assets/images/logodarktheme.png";
import whitelogo from "../Assets/images/logo-whitetheme.png";
import Theme from "./separate/Theme";
import { useLayoutEffect } from "react";
import { ToastContainer, toast } from "react-toastify";

function Register({ setShowNavbar }) {
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(eyeOff);
  const [loader, setLoader] = useState(false);
  const Navigate = useNavigate();

  const handleToggle = () => {
    if (type === "password") {
      setIcon(eye);
      setType("text");
    } else {
      setIcon(eyeOff);
      setType("password");
    }
  };

  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const passwordMatch =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[~()\-_+=!@#$%^&*<>`:{}\[\];'?",.])(?=.*\d).{8,}$/;

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    touched,
    errors,
    values,
    resetForm,
    setValues,
  } = useFormik({
    initialValues: {
      email: "",
      password: "",
      referral: "",
      termsAndConditions: false,
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email("Please enter valid email!")
        .required("E-mail is required!")
        .matches(regex, "Invalid Email!"),
      password: Yup.string()
        .required(" Password is required!")
        .matches(
          passwordMatch,
          "Password must contain at least 8 characters, one uppercase letter, one number, and atleast one symbol!"
        ),
      termsAndConditions: Yup.bool().oneOf(
        [true],
        "You need to accept the terms and conditions"
      ),
    }),
    onSubmit: async (values) => {
      setLoader(true)
      try {
        const payload = {
          email: values.email,
          password: values.password,
          referral_code: values.referral
        };
        let params = {
          url: `register`,
          method: "POST",
          data: payload,
        };
        const res = await makeApiRequest(params);
        if (res.status) {
          toast.success(res.message);
          setLoader(false)
          setTimeout(() => {
            if (res.status == true) {
              Navigate("/login");
            }
          }, 5000);
        } else {
          toast.error(res.message);
          setLoader(false)
        }
      } catch (error) {
        toast.error(error.message);
        setLoader(false)
      }
    },
  });

  useLayoutEffect(() => {
    setShowNavbar(false);
  }, []);

  return (
    <div>
      <ToastContainer />

      <section className="register-bg-styling-css login-section">
        <div className="row justify-content-center align-items-center m-0">
          <div className="col-lg-11">
            <div className="pt-4">
              <a class="navbar-brand whitelogo  ms-lg-5" href="/">
                <img src={darklogo} className="logo " alt="logo" />
              </a>
              <a class="navbar-brand darklogo ms-lg-5" href="/">
                <img src={whitelogo} className="logo " alt="logo" />
              </a>
            </div>
            <div className="theme-fixed">
              <Theme />
            </div>
          </div>
        </div>
        <div className="container-fluid col-lg-9">
          <div className="row min-vh text-center align-items-center justify-content-lg-between justify-content-end flex-lg-row-reverse m-0">
            <div className="col-lg-5 col-md-8 text-center py-4 register-card-bg-styling login-inputs-css pt-md-5">
              <img src={profile3} className="usermask-image-css" />
              <form
                className="new-contact-form"
                name="footercontact"
                id="footercontact"
                autocomplete="off"
                novalidate="novalidate"
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <p className="fs-20 fw-500 text-white">Register</p>
                {/* <input type="text" class="form-control " placeholder="Email" id="exampleInputPassword1" onChange={(e) => setEmail(e.target.value)} /> */}
                <div className="mb-3">
                  <div class="input-group">
                    <input
                      type="text"
                      name="email"
                      class="form-control mb-0"
                      id="exampleInputPassword1"
                      placeholder="Email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      onKeyPress={(event) => {
                        const keyCode = event.which || event.keyCode;
                        const keyValue = String.fromCharCode(keyCode);

                        // Allow only numeric values (0-9) and the backspace key (8)
                        if (!/^[a-zA-Z0-9._@]+$/.test(keyValue)) {
                          event.preventDefault();
                        }
                      }}
                      invalid={touched.email && errors.email ? true : false}
                    />
                  </div>
                  {touched.email && errors.email && (
                    <small className="invalid-login error password-text-33">
                      {errors.email}
                    </small>
                  )}
                </div>

                <div className="mb-3">
                  <div class="input-group mt-2">
                    <input
                      type={type}
                      name="password"
                      // type="password"
                      class="form-control with-input mb-0"
                      id="exampleInputPassword1"
                      placeholder="Password"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      invalid={
                        touched.password && errors.password ? true : false
                      }
                    />
                    <span
                      class="input-group-text eye-icon"
                      style={{ cursor: "pointer" }}
                      onClick={handleToggle}
                    >
                      <Icon class="absolute mr-10" icon={icon} size={25} />
                    </span>
                  </div>
                  {touched.password && errors.password && (
                    <small className="invalid-login error password-text-33">
                      {errors.password}
                    </small>
                  )}
                </div>

                <div className="mb-3">
                  <div class="input-group mt-2">
                    <input
                      type="text"
                      name="referral"
                      class="form-control with-input mb-0"
                      id="exampleInputReferral1"
                      placeholder="Referral Code"
                      aria-label="Referral"
                      aria-describedby="basic-addon1"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.referral}
                    />
                  </div>
                </div>

                <div class="mb-3 mt-3 d-flex flex-row align-items-center text-center justify-content-center">
                  <div>
                    <Link to="/terms">
                      <label
                        class="form-check-label text-white"
                        for="exampleCheck1"
                        style={{ cursor: "pointer" }}
                      >
                        Terms & Conditions
                      </label>
                    </Link>
                  </div>
                  <div className="ms-2 ">
                    <input
                      type="checkbox"
                      class="form-check-input check-box"
                      name="termsAndConditions"
                      checked={values.termsAndConditions}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="exampleCheck1"
                    />
                  </div>
                </div>
                <div>
                  {touched.termsAndConditions && errors.termsAndConditions && (
                    <small className="invalid-login error password-text-33">
                      {errors.termsAndConditions}
                    </small>
                  )}
                </div>

                {loader == true ? (
                  <a className="spinner-btn-1">
                    <Spinner animation="border" />
                  </a>
                ) : (
                  <button className="spinner-btn-1 p-0 mt-2"> Sign Up </button>
                )}
              </form>
            </div>

            <div className="col-lg-6 col-md-7 mt-lg-5 pt-lg-5 mb-5">
              <p className="fcaps heading-1-css  mb-2 d-lg-block d-none">
                welcome{" "}
              </p>
              <p className="fs-20  text-center d-lg-block d-none">
                Login continue your journey with the largest community of
                innovators
              </p>
              <p className="mt-3 mt-lg-5 fs-20 fw-500">
                Already have an account?
              </p>
              <a href="/login" className="text-dark">
                <button className="btn main-btn-css-1">Login</button>
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Register;
