import React, { useEffect, useRef, useState } from "react";
import { makeApiRequest } from "../../config/AxiosService";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment"

function ReferredTable() {

    const [referredData, setReferredData] = useState([])
    const [currentreferralpage, setCurrentReferralPage] = useState(1)
    const [totalBNB, setTotalBNB] = useState(0)
    const [totalBTC, setTotalBTC] = useState(0)
    const [totalTRX, setTotalTRX] = useState(0)
    const itemsPerPage = 5;

    const getreferredHistory = async () => {
        try {
            let params = {
                url: `referredHistory`,
                method: 'GET',
            };
            let response = await makeApiRequest(params);
            if (response.status == true) {
                const ReferredHistory = response.data;
                setReferredData(ReferredHistory);
            } else {
                setReferredData('');
            }
        } catch (error) {
            console.log('error', error);
        }
    };

    useEffect(() => {
        getreferredHistory()
    }, []);

    useEffect(() => {
        if (referredData.length > 0) {
            const startIndex = (currentreferralpage - 1) * itemsPerPage;
            const endIndex = startIndex + itemsPerPage;
            const totalData = referredData.slice(startIndex, endIndex);
            const totalBNBAmount = totalData.reduce((acc, order) => acc + order.bep_amnt, 0);
            const totalBTCAmount = totalData.reduce((acc, order) => acc + order.btc_amnt, 0);
            const totalTRXAmount = totalData.reduce((acc, order) => acc + order.trc_amnt, 0);
            setTotalBNB((totalBNBAmount).toFixed(2));
            setTotalBTC((totalBTCAmount).toFixed(2));
            setTotalTRX((totalTRXAmount).toFixed(2));
        }
    }, [currentreferralpage, referredData]);

    const indexOfLastRecord = currentreferralpage * itemsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - itemsPerPage;
    const currentRecords = referredData.slice(indexOfFirstRecord, indexOfLastRecord);
    const totalPages = Math.ceil(referredData.length / itemsPerPage);

    const handleNextPage = () => {
        if (currentreferralpage < totalPages) {
            setCurrentReferralPage(currentreferralpage + 1);
        }
    };

    const handlePrevPage = () => {
        if (currentreferralpage > 1) {
            setCurrentReferralPage(currentreferralpage - 1);
        }
    };


    return (
        <div className="App refer-section">
            <ToastContainer />
            <div className="container-fluid" style={{ marginLeft: "15px" }}>
                <div className="custom-saled-order text-dark">
                    <div className="row">
                        <div className="col-sm-12">

                            <div className="py-3 mt-lg-3 justify-content-center d-flex justify-content-between">
                                <h3 className=" component-user  text-center ms-lg-0">Referred History</h3>
                            </div>
                            <div className="custom-datascroll">
                                <table class="table w-100 mb-0">
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Email</th>
                                            <th>Refered Email</th>
                                            <th>BEP-20</th>
                                            <th>BTC</th>
                                            <th>TRC-20</th>
                                            <th>Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentRecords.length > 0 ? (
                                            currentRecords.map((refer, index) => (
                                                <tr key={index}>
                                                    <td>{(currentreferralpage - 1) * itemsPerPage + index + 1}</td>
                                                    <td>{refer.referral_email}</td>
                                                    <td>{refer.email}</td>
                                                    <td>{refer.bep_amnt}</td>
                                                    <td>{refer.btc_amnt}</td>
                                                    <td>{refer.trc_amnt}</td>
                                                    <td>{moment(refer.date).format('MMMM Do YYYY, h:mm:ss a')}</td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="9" className="text-center">
                                                    No Orders
                                                </td>
                                            </tr>
                                        )}
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>Total: {Number(totalBNB)}</td>
                                            <td>Total: {Number(totalBTC)}</td>
                                            <td>Total: {Number(totalTRX)}</td>
                                            <td></td>
                                        </tr>
                                    </tbody>

                                </table>
                            </div>
                            <nav>
                                <ul className="pagination justify-content-center">
                                    <li className={`page-item ${currentreferralpage === 1 ? 'disabled' : ''}`}>
                                        <button className="page-link" onClick={handlePrevPage}>
                                            Previous
                                        </button>
                                    </li>
                                    <li className={`page-item ${currentreferralpage === totalPages ? 'disabled' : ''}`}>
                                        <button className="page-link" onClick={handleNextPage}>
                                            Next
                                        </button>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default ReferredTable