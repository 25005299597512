import React, { useEffect, useState } from "react";
import profile from "../../Assets/images/dashboard/profile.png";
import accountname from "../../Assets/images/dashboard/account name.png";
import mail from "../../Assets/images/dashboard/mail.png";
import gender1 from "../../Assets/images/dashboard/profile.png";
import referal from "../../Assets/images/dashboard/kyc.png";
import { ToastContainer, toast } from "react-toastify";
import Spinner from "react-bootstrap/Spinner";
import Editprofile from "./Editprofile";
import { makeApiRequest } from "../../config/AxiosService";
import gen from "../../Assets/images/dashboard/gender.png";
import acname from "../../Assets/images/dashboard/acc name.png";


function Profile() {

  const [getProfileData, setGetProfileData] = useState([]);

  const [image, setImage] = useState({image: "", aadharFrontImage: "", aadharBackImage: "", dlImage: ""});
  // const [aadharFrontImage, setAadharFrontImage] = useState("");
  // const [aadharBackImage, setAadharBackImage] = useState("");
  // const [dlImage, setDLImage] = useState("");
  // const [editID, setEditID] = useState("")
  const [name, setName] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [addAdminError, setAddAdminError] = useState("");

  const getProfileDetails = async () => {
    try {
      let params = {
        url: `getProfileDetails`,
        method: 'GET',
      };
      let response = await makeApiRequest(params);
      if (response.status == true) {
        const getProfile = response.data;
        setGetProfileData(getProfile);
        // setImage(getProfile.image)
        setName(getProfile.name)
        setAge(getProfile.age)
        setGender(getProfile.gender)
      } else {
        setGetProfileData('');
      }
    } catch (error) {
      console.log('error', error);
    }
  };
  
  const handleUpdate = async (event) => {
    event.preventDefault();
    const validationErrors = {};
    const regex = /^\d+(\.\d{0,2})?$/;

    try {
      if (!image) {
        validationErrors.image = "*Image is required";
      }
      if (!name) {
        validationErrors.name = "*Name is required";
      }
      if (!age) {
        validationErrors.age = "*Age is required";
      } else if (!regex.test(age)) {
        validationErrors.age = "*Please enter a valid number with up to two decimal places";
      } else if (age < 0) {
        validationErrors.age = "*Negative value not allowed";
      }
      if (!gender) {
        validationErrors.gender = "*Gender is required";
      }

      setAddAdminError(validationErrors);
      if (Object.keys(validationErrors).length > 0) {
        return;
      } else {
        setIsLoading(true);
        const formData = new FormData();
        formData.append("image", image.image);
        formData.append("aadharFrontImage", image.aadharFrontImage);
        formData.append("aadharBackImage", image.aadharBackImage);
        formData.append("dlImage", image.dlImage);
        formData.append("name", name);
        formData.append("age", age);
        formData.append("gender", gender);
        const params = {
          url: `edit-profile`,
          method: "POST",
          data: formData,
          header:"image"
        };
        const response = await makeApiRequest(params);
        if (response.status === true) {
          setIsLoading(false);
          toast.success(response.message);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          setIsLoading(false);
          toast.warn(response.message);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProfileDetails()
  }, []);

  return (
    <>
      <div className="App">
        <ToastContainer />
        <div className="container-fluid">
          <div className="custom-profile">
            <div className="">
              <div className="row">
                <div className="col-lg-12 p-0">
                  <div className="row justify-content-center align-items-center">
                    <div className="col-lg-8">
                      <div className="custom-inside-profile d-flex flex-lg-row flex-column align-items-center">
                        <img src={getProfileData.image} alt="Profile" className="profile-pic"></img>
                        <p className="mb-0 ms-lg-3 " style={{ color: "#BC9018", fontSize: "20px" }}>Profile</p>
                        <button
                          className=" ms-lg-auto mt-2 mt-lg-0"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                        // onClick={(event) => handleEdit(event)}
                        >
                          Edit
                        </button>
                      </div>
                      <div className="custom-account-setting mt-3">
                        <div className="custom-acccount-name d-flex flex-lg-row flex-column align-items-center">
                          <img src={acname} alt="Profile"></img>
                          <div className="text-center text-lg-start ms-lg-3">
                            <h4 style={{ color: "#BC9018", fontSize: "20px" }}>Account Name</h4>
                            <p className="mb-0">{getProfileData.name}</p>
                          </div>
                        </div>



                        <div className="custom-inside-profile d-flex flex-lg-row flex-column align-items-center mb-2">
                          <img src={mail} alt="Profile"></img>
                          <div className=" text-lg-start text-center ms-lg-3">
                            <h4 style={{ color: "#BC9018", fontSize: "20px" }}>Gmail</h4>
                            <p className="mb-0 email-ellipsis-css">
                              {getProfileData.email}
                            </p>
                          </div>
                        </div>

                        <div className="custom-acccount-name d-flex flex-lg-row flex-column align-items-center">
                          <img src={gen} alt="Profile"></img>
                          <div className="text-center text-lg-start ms-lg-3">
                            <h4 style={{ color: "#BC9018", fontSize: "20px" }}>Gender</h4>
                            <p className="mb-0" style={{ wordBreak: "break-all" }}>{getProfileData.gender}</p>
                          </div>
                        </div>

                        <div className="custom-inside-profile d-flex flex-lg-row flex-column align-items-center mb-2">
                          <img src={referal} alt="Profile"></img>
                          <div className="text-lg-start text-center ms-lg-3">
                            <h4 style={{ color: "#BC9018", fontSize: "20px" }}>Referal Code</h4>
                            <p className="mb-0">{getProfileData.referral_code}</p>
                          </div>
                        </div>
                        
                        <div>Documents</div>
                        <div className="custom-inside-profile d-flex flex-lg-row flex-column align-items-center mb-2">
                          <img src={getProfileData.dlImage} alt="Driving Licence"/>
                          <div className="text-lg-start text-center ms-lg-3">
                            <h4 style={{ color: "#BC9018", fontSize: "20px" }}>Driving Licence</h4>
                          </div>
                        </div>
                        <div className="custom-inside-profile d-flex flex-lg-row flex-column align-items-center mb-2">
                          <img src={getProfileData.aadharFrontImage} alt="Aadhar Front Image" />
                          <div className="text-lg-start text-center ms-lg-3">
                            <h4 style={{ color: "#BC9018", fontSize: "20px" }}>Aadhar Front Image</h4>
                          </div>
                        </div>
                        <div className="custom-inside-profile d-flex flex-lg-row flex-column align-items-center mb-2">
                          <img src={getProfileData.aadharBackImage} alt="Aadhar Back Image" />
                          <div className="text-lg-start text-center ms-lg-3">
                            <h4 style={{ color: "#BC9018", fontSize: "20px" }}>Aadhar Back Image</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



      <div
        class="modal fade custom-modal-update-profil1"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog custom-modal-update-profile ">
          <div class="modal-content kyc-verification">
            <div class="modal-header">
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div>
                <div className='App custom-tobulk-nav'>
                  {/* <ToastContainer  /> */}
                  <div className='container-fluid'>
                    {/* {window.location.pathname == '/editprofile' ? (<Navbar />) : (<></>)} */}
                    <div className=' custom-edit-profile-pop-up'>
                      <div className=''>
                        <div className='row'>
                          <div className='col-sm-12'>
                            <div className='custom-inside-bulk-order'>
                              <h2 className="fs-4 text-color2">UPDATE PROFILE</h2>
                            </div>
                            <form
                              className="new-contact-form"
                              name="footercontact"
                              id="footercontact"
                              autoComplete="off"
                              noValidate="novalidate"
                              onSubmit={(event) =>handleUpdate(event)}
                            >
                              <div className='row align-items-center justify-content-center'>
                                <div className='cols-lg-12 custom-bulk-box'>
                                  <div className='custom-contact-form align-items-center justify-content-center'>
                                    <div className="mb-3 row align-items-center">
                                      {/* Image Upload Input */}
                                      <label htmlFor="imageUpload" className="col-sm-5  col-form-label custom-contact-color">Upload Image</label>
                                      <div className="col-sm-5 ">
                                        <div className="custom-file-input-wrapper">
                                          <input
                                            type="file"
                                            name="image"
                                            className="custom-file-input"
                                            id="imageUpload"
                                            accept="image/png, image/jpeg"
                                            // value={image}
                                            onChange={(e) => {
                                              setImage(prev => ({
                                                ...prev,
                                                image: e.target.files[0]
                                              }));
                                              setAddAdminError((prevErrors) => ({
                                                ...prevErrors,
                                                image: "",
                                              }));
                                            }}
                                          />
                                          <label htmlFor="imageUpload" className="custom-file-label">
                                            Choose Profile Image
                                          </label>
                                        </div>
                                        <span style={{ color: "#FF0000" }}>
                                          {addAdminError.image && <>{addAdminError.image}</>}
                                        </span>
                                      </div>
                                    </div>
                                    
                                    <div className="mb-3 row align-items-center">
                                      <label htmlFor="inputPassword" className="col-sm-5  col-form-label custom-contact-color">Nick name</label>
                                      <div className="col-sm-5 ">
                                        <input
                                          type="text"
                                          name="name"
                                          className="form-control me-auto py-2 custom-value-color"
                                          id="inputPassword"
                                          placeholder="Name"
                                          maxLength={15}
                                          value={name}
                                          onChange={(e) => {
                                            setName(e.target.value);
                                            setAddAdminError((prevErrors) => ({
                                              ...prevErrors,
                                              name: "",
                                            }));
                                          }}
                                        />
                                      </div>
                                      <span style={{ color: "#FF0000" }}>
                                        {addAdminError.name && <>{addAdminError.name}</>}
                                      </span>
                                    </div>

                                    <div className="mb-3 row align-items-center">
                                      <label htmlFor="inputPassword" className="col-sm-5  col-form-label custom-contact-color">Gender</label>
                                      <div className="col-sm-5 ">
                                        <input
                                          type="text"
                                          name="gender"
                                          className="form-control me-auto"
                                          id="inputPassword"
                                          placeholder="Gender"
                                          maxLength={100}
                                          value={gender}
                                          onChange={(e) => {
                                            setGender(e.target.value);
                                            setAddAdminError((prevErrors) => ({
                                              ...prevErrors,
                                              gender: "",
                                            }));
                                          }}
                                        />
                                      </div>
                                      <span style={{ color: "#FF0000" }}>
                                        {addAdminError.gender && <>{addAdminError.gender}</>}
                                      </span>
                                    </div>

                                    <div className="mb-3 row align-items-start">
                                      <label htmlFor="inputPassword" className="col-sm-5 col-form-label custom-contact-color">Age</label>
                                      <div className="col-sm-5">
                                        <input
                                          type="text"
                                          name="age"
                                          className="form-control me-auto"
                                          id="inputPassword"
                                          placeholder="Age"
                                          maxLength={100}
                                          value={age}
                                          onChange={(e) => {
                                            setAge(e.target.value);
                                            setAddAdminError((prevErrors) => ({
                                              ...prevErrors,
                                              age: "",
                                            }));
                                          }}
                                        />
                                      </div>
                                      <span style={{ color: "#FF0000" }}>
                                        {addAdminError.age && <>{addAdminError.age}</>}
                                      </span>
                                    </div>
                                    {/* Upload Documents Start*/}
                                    <div className="mb-3 row align-items-center">
                                      {/* Image Upload Input */}
                                      <label htmlFor="aadharFrontImageUpload" className="col-sm-5 col-form-label custom-contact-color">Upload Aadhar Front</label>
                                      <div className="col-sm-5">
                                        <div className="custom-file-input-wrapper">
                                          <input
                                            type="file"
                                            name="image"
                                            className="custom-file-input"
                                            id="aadharFrontImageUpload"
                                            accept="image/png, image/jpeg"
                                            // value={image}
                                            onChange={(e) => {
                                              setImage(prev => ({
                                                ...prev,
                                                aadharFrontImage: e.target.files[0]
                                              }));
                                              setAddAdminError((prevErrors) => ({
                                                ...prevErrors,
                                                image: "",
                                              }));
                                            }}
                                          />
                                          <label htmlFor="aadharFrontImageUpload" className="custom-file-label">
                                            Choose File OF Aadhar Front
                                          </label>
                                        </div>
                                        <span style={{ color: "#FF0000" }}>
                                          {addAdminError.image && <>{addAdminError.image}</>}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="mb-3 row align-items-center">
                                      {/* Image Upload Input */}
                                      <label htmlFor="aadharBackImageUpload" className="col-sm-5 col-form-label custom-contact-color">Upload Aadhar Back</label>
                                      <div className="col-sm-5">
                                        <div className="custom-file-input-wrapper">
                                          <input
                                            type="file"
                                            name="image"
                                            className="custom-file-input"
                                            id="aadharBackImageUpload"
                                            accept="image/png, image/jpeg"
                                            // value={image}
                                            onChange={(e) => {
                                              setImage(prev => ({
                                                ...prev,
                                                aadharBackImage: e.target.files[0]
                                              }));
                                              setAddAdminError((prevErrors) => ({
                                                ...prevErrors,
                                                image: "",
                                              }));
                                            }}
                                          />
                                          <label htmlFor="aadharBackImageUpload" className="custom-file-label">
                                          Choose File OF Aadhar Back
                                          </label>
                                        </div>
                                        <span style={{ color: "#FF0000" }}>
                                          {addAdminError.image && <>{addAdminError.image}</>}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="mb-3 row align-items-center">
                                      {/* Image Upload Input */}
                                      <label htmlFor="dlImageUpload" className="col-sm-5 col-form-label custom-contact-color">Upload Driving Licence</label>
                                      <div className="col-sm-5">
                                        <div className="custom-file-input-wrapper">
                                          <input
                                            type="file"
                                            name="image"
                                            className="custom-file-input"
                                            id="dlImageUpload"
                                            accept="image/png, image/jpeg"
                                            // value={image}
                                            onChange={(e) => {
                                              setImage(prev => ({
                                                ...prev,
                                                dlImage: e.target.files[0]
                                              }));
                                              setAddAdminError((prevErrors) => ({
                                                ...prevErrors,
                                                image: "",
                                              }));
                                            }}
                                          />
                                          <label htmlFor="dlImageUpload" className="custom-file-label">
                                            Choose File OF Driving Licence
                                          </label>
                                        </div>
                                        <span style={{ color: "#FF0000" }}>
                                          {addAdminError.image && <>{addAdminError.image}</>}
                                        </span>
                                      </div>
                                    </div>
                                    {/* Upload Documents end*/}

                                    <div className="row align-items-end">
                                      <div className="col-lg-12 mt-4">
                                        <div className="custom-calender-btn text-center">
                                          <button type="submit" disabled={isLoading}>
                                            {isLoading ? "Submitting..." : "Submit"}
                                          </button>

                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer border-0"></div>
          </div>
        </div>
      </div>

    </>
  );
}

export default Profile;
