import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import darklogo from "../Assets/images/logodarktheme.png";
import whitelogo from "../Assets/images/logo-whitetheme.png";

function Error({ setShowNavbar }) {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/');
  };

  useEffect(() => {
    setShowNavbar(false);
  }, [])

  return (
    <div className="App ">
      <section className="error-page-banner">
        <div className="container-fluid col-lg-11">
          <div className="row min-vh-100 align-items-center justify-content-center text-center">
            <div className="col-lg-7">
              <div className="card p-3">
                <a class="navbar-brand darklogo "><img src={darklogo} className="logo " alt="logo" /></a>
                <a class="navbar-brand  whitelogo "><img src={whitelogo} className="logo " alt="logo" /></a>
                <h1 className="er-tx1">
                  404
                </h1>
                <p className="er-tx2">
                  Sorry! That page can't be found.
                </p>
                <div>
                  <a href="/" type="button" class="log-btn " onClick={handleClick} >Return Home</a>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Error;
