import React, { useEffect, useState } from "react";
import $ from 'jquery'
import { GrClose } from 'react-icons/gr';
import { MdWebAsset } from 'react-icons/md';
import { TbLogout } from 'react-icons/tb';
import { CgProfile } from "react-icons/cg";
import { useLocation, useParams } from "react-router-dom";
import render12 from '../Assets/images/logodarktheme.png';
import Withdraw from "./DashboardPages/Withdraw";
import Deposit from "./DashboardPages/Deposit";
import Profile from "./DashboardPages/Profile";
import Transactionhistory from "./DashboardPages/Transactionhistory";
import Referred from './DashboardPages/ReferredHistory'
import { useLayoutEffect } from 'react';
import Theme from "./separate/Theme"
import { MdHistory } from "react-icons/md";
import { PiHandWithdrawFill } from "react-icons/pi";
import { PiHandDepositBold } from "react-icons/pi";
import RewardHistory from "./DashboardPages/RewardHistory";
import RewardData from "./DashboardPages/RewardData";
// import RewardData from "./DashboardPages/RewardData";


$(document).ready(function () {
  if ($(window).width() < 600) {       // if width is less than 600px
    $(".nav-link").click(function () {
      $("#mySidenav").css("width", "0px");
    });
  }
  else {
    // $('#v-pills-asset-tab').click(function () {
    //   location.reload();
    // });
  }

  $("#mobile-three-line-collapse").click(function () {
    $("#mySidenav").css("width", "250px");
  });
  $("#mobile-close-collapse").click(function () {
    $("#mySidenav").css("width", "0px");
  });
  $(".dashboard-profile-table-tabs-section-hide").hide();
  $(".details-button-navigation button").click(function () {
    $(".dashboard-profile-section-hide").hide();
    $(".dashboard-profile-table-tabs-section-hide").show();
    $("#mySidenav").css("width", "100px");
    $(".dashboard-main-section").css("margin-left", "100px");
    $(".dashboard-navbar-brand img").css("width", "50px");
    $(".dashboard-navbar-brand img").css("height", "50px");
    $("#mobile-close-arrow-left").hide();
    $(".dashboard-text-1").hide();
    $("#mobile-close-arrow-right").show();
  });
  $(".button-dashboard-table-back").click(function () {
    $(".dashboard-profile-section-hide").show();
    $(".dashboard-profile-table-tabs-section-hide").hide();
    $("#mySidenav").css("width", "250px");
    $(".dashboard-main-section").css("margin-left", "250px");
    $(".dashboard-navbar-brand img").css("width", "80px");
    $(".dashboard-navbar-brand img").css("height", "80px");
    $("#mobile-close-arrow-left").show();
    $(".dashboard-text-1").show();
    $("#mobile-close-arrow-right").hide();
  });



  $("#mobile-close-arrow-right").hide();
  $("#mobile-close-arrow-left").click(function () {
    $("#mySidenav").css("width", "100px");
    $(".dashboard-main-section").css("margin-left", "100px");
    $(".dashboard-navbar-brand img").css("width", "50px");
    $(".dashboard-navbar-brand img").css("height", "50px");
    $("#mobile-close-arrow-left").hide();
    $(".dashboard-text-1").hide();
    $("#mobile-close-arrow-right").show();
  });
  $("#mobile-close-arrow-right").click(function () {
    $("#mySidenav").css("width", "250px");
    $(".dashboard-main-section").css("margin-left", "250px");
    $(".dashboard-navbar-brand img").css("width", "80px");
    $(".dashboard-navbar-brand img").css("height", "80px");
    $("#mobile-close-arrow-left").show();
    $(".dashboard-text-1").show();
    $("#mobile-close-arrow-right").hide();
  });


  if ($('#mySidenav').css('width') === '100px') {
    $('#mySidenav').css('a');
  }
});



export default function Dashboard(props) {
  const location = useLocation();
  const params = useParams()
  console.log("params-----",params.id);

  const ampouleno = props.ampouleno
  const { setShowNavbar } = props;


  useLayoutEffect(() => {
    setShowNavbar(false);
}, [])


  const logOut = () => {
    localStorage.clear();
    window.location.href = '/login'
  }

  return (
    <>
    

    <div className="container-fluid dashboard-section">
    <div className="theme-fixed">
    <Theme />
    </div>                           
      <div className="row">
        {/* Sidebar */}
        <div>
          <span id="mobile-three-line-collapse">&#9776;</span>
          <div className="dashboard-sidenav-section" id="mySidenav">
            <nav
              id="sidebar"
              className="col-md-12 col-lg-12 d-md-block sidebar component-navbar"
            >
              <div className="d-flex flex-row">
                <div className="ms-auto me-3">
                  <span id="mobile-close-collapse"><GrClose />
                  </span>
                </div>
              </div>
             
              <div className="d-flex flex-row pt-2 mb-2">
                <div className="mx-auto">
                  <a className="navbar-brand" href="/"><img src={render12}></img></a>
                </div>
              </div>
              
              <div className="position-sticky">
                <ul className="nav flex-column nav-pills">
                <li className="nav-item">
                    <a
                      className={`nav-link component-tabs ${location.pathname === "/dashboard/profile" ? "active" : ""
                        }`}
                        href="/dashboard/profile"
                    >
                      <CgProfile className="sidenav-icon-size-css" /> Profile
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link component-tabs ${location.pathname === "/dashboard/referred" ? "active" : ""
                        }`}
                        href="/dashboard/referred"
                    >
                      <MdHistory className="sidenav-icon-size-css" /> ReferredHistory
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link component-tabs ${location.pathname === "/dashboard/deposit" ? "active" : ""
                        }`}
                      href="/dashboard/deposit"
                    >
                      <PiHandDepositBold className="sidenav-icon-size-css" /> Deposit
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link component-tabs ${location.pathname === "/dashboard/withdraw"
                        ? "active"
                        : ""
                        }`}
                      href="/dashboard/withdraw"
                    >
                      <PiHandWithdrawFill className="sidenav-icon-size-css " /> Withdraw

                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link component-tabs ${location.pathname === "/dashboard/reward-history" ? "active" : ""
                        }`}
                        href="/dashboard/reward-history"
                    >
                      <MdHistory className="sidenav-icon-size-css" /> Reward History
                    </a>
                  </li>
                 
                  <li className="nav-item">
                    <a
                      className={`nav-link component-tabs ${location.pathname === "/dashboard/history" ? "active" : ""
                        }`}
                        href="/dashboard/history"
                    >
                      <MdHistory className="sidenav-icon-size-css" /> Transaction History
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link component-tabs ${location.pathname === "/dashboard/logout" ? "active" : ""
                        }`}
                      onClick={logOut}
                    >
                      <TbLogout className="sidenav-icon-size-css" /> Logout
                    </a>
                  </li>

                </ul>
              </div>
            </nav>
          </div>
        </div>



        {/* Main Content */}
        <main className="col-md-12 ms-sm-auto col-lg-10 px-md-4 ">
          {location.pathname === "/dashboard/withdraw" && <Withdraw />}
          {location.pathname === "/dashboard/deposit" && <Deposit />}
          {location.pathname === "/dashboard/profile" && <Profile />} 
          {location.pathname === "/dashboard/referred" && <Referred />}
          {location.pathname === "/dashboard/history" && <Transactionhistory />}            
          {location.pathname === "/dashboard/reward-history" && <RewardHistory />}            
          {location.pathname === `/dashboard/reward-data/${params.id}` && <RewardData/>}            
        </main>
      </div>
    </div>
    </>
  );
}

