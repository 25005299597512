
  let config = {};
  let environment = "live";
  if (environment == "local") {
    config = {
      frontendurl: "http://localhost:3000",
      backendurl: "http://localhost:5004/",
      btc: `https://api.blockcypher.com/v1/btc/test3/`,
      BNB_URL: "https://testnet.bscscan.com",
      BTC_URL: 'https://live.blockcypher.com/btc-testnet',
      TRX_Explorer_URL: "https://nile.tronscan.org/#",
      btcRegex: /^(tb1|[mn2])[a-zA-HJ-NP-Z0-9]{25,39}$/
    };
  } else {
    config = {
      frontendurl: "https://willgrow.io",
      backendurl: "https://api.willgrow.io/",
      btc: `https://api.blockcypher.com/v1/btc/main/`,
      BNB_URL: "https://bscscan.com",
      BTC_URL: 'https://btcscan.org',
      TRX_Explorer_URL: "https://tronscan.org/#",
      btcRegex: /^(bc1|[13])[a-zA-HJ-NP-Z0-9]{25,39}$/
    };
  }
  export default config;