import React from "react";
import { useEffect, useState } from "react";
// import {  toast } from "react-toastify";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { eye } from "react-icons-kit/feather/eye";
import { Icon } from "react-icons-kit";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { makeApiRequest } from "../config/AxiosService";
import profile3 from "../Assets/images/login-signup/profile3.png";
import darklogo from "../Assets/images/logodarktheme.png";
import whitelogo from "../Assets/images/logo-whitetheme.png";
import Theme from "../pages/separate/Theme";
import { useLayoutEffect } from "react";
import { ToastContainer, toast } from "react-toastify";

function Login({ setShowNavbar }) {
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(eyeOff);
  const [loader, setLoader] = useState(false);
  const Navigate = useNavigate();

  const handleToggle = () => {
    if (type === "password") {
      setIcon(eye);
      setType("text");
    } else {
      setIcon(eyeOff);
      setType("password");
    }
  };

  useLayoutEffect(() => {
    setShowNavbar(false);
  }, []);

  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    touched,
    errors,
    values,
    resetForm,
    setValues,
  } = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email("Please enter valid email!")
        .required("E-mail is required!")
        .matches(regex, "Invalid Email!"),
      password: Yup.string().required(" Password is required!"),
    }),
    onSubmit: async (values) => {
      try {
        const payload = {
          email: values.email,
          password: values.password,
        };
        let params = {
          url: `userLogin`,
          method: "POST",
          data: payload,
        };
        const response = await makeApiRequest(params);
        if (response.status === true) {
          setLoader(true);
          toast.success(response.message);
          localStorage.setItem("email", response.data.email);
          localStorage.setItem("token", response.token);

          setTimeout(() => {
            Navigate("/dashboard/profile");
          }, 5000);
        } else {
          setLoader(false);
          toast.error(response.message);
        }
      } catch (error) {
        setLoader(false);
        toast.error(error.message);
        console.log("error", error.message);
      }
    },
  });

  return (
    <div>
      <ToastContainer />
      <section className="login-bg-styling-css login-section">
        <div className="row justify-content-center align-items-center m-0">
          <div className="col-lg-11 ">
            <div className="pt-4">
              <a class="navbar-brand darklogo  ms-lg-5" href="/">
                <img src={darklogo} className="logo " alt="logo" />
              </a>
              <a class="navbar-brand whitelogo ms-lg-5" href="/">
                <img src={whitelogo} className="logo " alt="logo" />
              </a>
            </div>
            <div className="theme-fixed">
              <Theme />
            </div>
          </div>
        </div>
        {/* <a class="navbar-brand" href="/"><img src={render12} className="mx-4 my-2"></img></a> */}
        <div className="container-fluid col-lg-9">
          <div className="row min-vh text-center align-items-center justify-content-lg-between justify-content-center g-4 m-0">
            <div className="col-lg-5 col-md-8 text-center py-4 register-card-bg-styling login-inputs-css">
              <img src={profile3} className="usermask-image-css" />
              <form
                className="new-contact-form"
                name="footercontact"
                id="footercontact"
                autocomplete="off"
                novalidate="novalidate"
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                  return false;
                }}
              >
                <p className="fs-20 fw-500 text-white">Login</p>
                <div className="mb-3">
                  <div class="input-group">
                    <input
                      type="text"
                      name="email"
                      class="form-control mb-1 with-inputs"
                      id="exampleInputPassword1"
                      placeholder="Email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      onKeyPress={(event) => {
                        const keyCode = event.which || event.keyCode;
                        const keyValue = String.fromCharCode(keyCode);

                        // Allow only numeric values (0-9) and the backspace key (8)
                        if (!/^[a-zA-Z0-9._@]+$/.test(keyValue)) {
                          event.preventDefault();
                        }
                      }}
                      invalid={touched.email && errors.email ? true : false}
                    />
                  </div>
                  {touched.email && errors.email && (
                    <small className="invalid-login error password-text-33">
                      {errors.email}
                    </small>
                  )}
                </div>

                <div className="mb-3">
                  <div class="input-group">
                    <input
                      type={type}
                      // type="password"
                      class="form-control with-input mb-0"
                      id="exampleInputPassword1"
                      name="password"
                      placeholder="Password"
                      aria-label="Password"
                      aria-describedby="basic-addon1"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      invalid={
                        touched.password && errors.password ? true : false
                      }
                    />
                    {/* <span class="input-group-text  eye-icon" id="basic-addon1"><FaEye /></span> */}
                    <span
                      class="input-group-text eye-icon"
                      onClick={handleToggle}
                    >
                      <Icon class="absolute mr-10" icon={icon} size={25} />
                    </span>
                  </div>
                  {touched.password && errors.password && (
                    <small className="invalid-login error password-text-33">
                      {errors.password}
                    </small>
                  )}
                </div>
                <div className="mb-2">
                  <a
                    className="fs-20 fw-500 text-white mb-4 mt-2"
                    href="/forgot-password"
                  >
                    Forgot Password ?
                  </a>
                </div>

                {loader == true ? (
                  <a className="spinner-btn-1">
                    <Spinner animation="border" />
                  </a>
                ) : (
                  <div className="d-flex flex-column">
                    <span>
                      {" "}
                      <button type="submit" className="spinner-btn-1 p-0">
                        {" "}
                        Login{" "}
                      </button>
                    </span>
                  </div>
                )}
              </form>
            </div>
            <div className="col-lg-6 mt-lg-5 pt-lg-5 mb-5">
              <p className="fcaps heading-1-css  mb-2 d-lg-block d-none">
                Welcome
              </p>
              <p className="fs-20  text-center d-lg-block d-none">
                Register continue your journey with the largest community of
                innovators
              </p>
              <p className="mt-lg-5 fs-20 fw-500">Don't have an account?</p>
              <a href="/register" className="text-dark">
                <button className="btn main-btn-css-1 ">Register</button>
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Login;
