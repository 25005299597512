import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import CountdownTimer from "../separate/Timer";
import { makeApiRequest } from "../../config/AxiosService";
import config from "../../config/Config";

function Widthdrawcrypto() {

  const location = useLocation();
  const Walletcoin = location.state ? location.state.coin : null;
  const email = localStorage.getItem("email");
  const [isLoading, setIsLoading] = useState(false);
  const [userBalance, setUserBalance] = useState("0");
  const [userAddress, setUserAddress] = useState("");
  const [txHash, setTxHash] = useState("")
  const [totalAmount, setTotalAmount] = useState("");
  const [values, setValue] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [symbol, setSymbol] = useState("");
  const [errors, setErrors] = useState("");
  const [adminAddress, setAdminAddress] = useState("");
  const [withdrawFee, setWithdrawFee] = useState(0);
  const evmAddressRegex = /^0x[a-fA-F0-9]{40}$/;
  const tronAddressRegex = /^T[a-zA-Z0-9]{33}$/;
  const btcAddressRegex = config.btcRegex;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const modalRef = useRef(null);

  useEffect(() => {
    if (isModalOpen) {
      const modal = new window.bootstrap.Modal(modalRef.current);
      modal.show();
      modalRef.current.addEventListener('hidden.bs.modal', () => setIsModalOpen(false));
    }
  }, [isModalOpen]);

  const customStyles = {
    menu: (base) => ({
      ...base,
      background: "var(--background-color-3)",
      margin: "0px",
      padding: "0px",
      color: "var(--color-1)",
      borderColor: "var(--card-color-1)",
    }),
    control: (base, state) => ({
      ...base,
      background: "transparent",
      color: "#000",
    }),
    option: (base, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...base,
        backgroundColor: isFocused ? "#F8C702" : "transparent",
        color: "black",
      };
    },
  };

  const options = [
    {
      values: "BTC",
      label: (
        <a href="#btc">
          <div className="select-color">
            <img
              src={
                "https://s2.coinmarketcap.com/static/img/coins/200x200/1.png"
              }
              className="pb-1 me-2"
              style={{ width: "20px", color: "black" }}
            />{" "}
            BTC
          </div>
        </a>
      ),
    },
    {
      values: "BNB",
      label: (
        <a href="#bnb">
          <div className="select-color">
            <img
              src={
                "https://bscscan.com/token/images/busdt_32.png"
              }
              className="pb-1 me-2"
              style={{ width: "20px" }}
            />{" "}
            BEP-20
          </div>
        </a>
      ),
    },
    {
      values: "TRX",
      label: (
        <a href="#trc">
          <div className="select-color">
            <img
              src={"https://cdn-icons-png.flaticon.com/512/12114/12114250.png"}
              className="pb-1 me-2"
              style={{ width: "20px" }}
            />{" "}
            TRC-20
          </div>
        </a>
      ),
    },
  ];

  useEffect(() => {
    coinName();
    userDetails();
  }, [values, userBalance]);

  const coinName = () => {
    if (Walletcoin == "BTC") {
      const btcOption = options.find((option) => option.values === "BTC");
      setValue(btcOption.values);
      setSelectedValue(btcOption);
      setSymbol(btcOption.Symbol);
    } else if (Walletcoin == "BNB") {
      const bnbOption = options.find((option) => option.values === "BNB");
      setValue(bnbOption.values);
      setSelectedValue(bnbOption);
      setSymbol(bnbOption.Symbol);
    } else if (Walletcoin == "TRX") {
      const TronOption = options.find((option) => option.values === "TRX");
      setValue(TronOption.values);
      setSelectedValue(TronOption);
      setSymbol(TronOption.Symbol);
    }
  };

  const HandleSelectChange = (e) => {
    setValue(e.values);
    const value = e.values;
    options.map((data) => {
      if (value === data.values) {
        setSelectedValue(data);
        setSymbol(data.Symbol);
      }
    });
  };

  async function userDetails() {
    let params = {
      url: "getProfileDetails",
      method: "Get",
    };
    const response = await makeApiRequest(params);
    if (response.status === true) {
      if (values == "BTC") {
        setUserBalance(response.walletUser.BTC_Balance);
      } else if (values == "BNB") {
        setUserBalance(response.walletUser.BEP_Balance);
      } else if (values == "TRX") {
        setUserBalance(response.walletUser.TRC_Balance);
      }
    } else {
      setUserBalance("0");
    }
  }

  const feeAmount = async () => {
    try {
      if (!values) {
        return toast.error("Select The Network...");
      }
      if (!userAddress || userAddress.address == '') {
        return toast.error("Enter the Wallet Address...");
      }
      if (!totalAmount || totalAmount.amount == '') {
        return toast.error("Enter the Amount...");
      }
      else {
        const payload = {
          coin: values,
          amount: totalAmount.amount
        }
        let params = {
          url: "adminBalance",
          method: "POST",
          data: payload
        }
        const adminResp = await makeApiRequest(params)
        if (adminResp.status == true) {
          let params = {
            url: "wallet_Data",
            method: "POST",
          };
          const response = await makeApiRequest(params)
          if (response) {
            let adminWallet;
            if (values == "BTC") {
              adminWallet = response.data.BTC_Address;
            } else if (values == "BNB") {
              adminWallet = response.data.EVM_Address;
            } else if (values == "TRX") {
              adminWallet = response.data.TRON_Address;
            }
            setAdminAddress(adminWallet);
          }
          const fee = (totalAmount.amount) * 0.3
          setWithdrawFee(fee)
          setIsModalOpen(true);
        } else {
          return toast.error(adminResp.message)
        }
      }
    } catch (error) {
      toast.error("Something Went Wrong...");
    }
  }

  async function userWithdrawal(event) {
    closeModal();
    event.preventDefault();
    setIsLoading(true);
    try {
      if (!txHash || txHash.hash == '') {
        return toast.error("Enter the Transaction Hash...");
      }

      const payload = {
        email: email,
        toAddress: userAddress.address,
        hash: txHash.hash,
        coin: values,
        withdrawFee: withdrawFee.toFixed(2),
        adminAddress: adminAddress,
        amount: totalAmount.amount,
      };
      let params = {
        url: "totalwithdraw",
        method: "POST",
        data: payload,
      };
      const response = await makeApiRequest(params);
      if (response.status === true) {
        setIsLoading(false);
        toast.success(response.message);
        setTimeout(() => {
          window.location.reload()
        }, 6000);
      } else {
        setIsLoading(false);
        toast.warn(response.message);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("Something Went Wrong...");
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (values === "BTC") {
      setUserAddress((prevAddress) => ({ ...prevAddress, [name]: value }));
      if (!btcAddressRegex.test(value)) {

        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Invalid Bitcoin address format",
        }));
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
      }
    } else if (values === "BNB") {
      setUserAddress((prevAddress) => ({ ...prevAddress, [name]: value }));
      if (!evmAddressRegex.test(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Invalid BNB address format",
        }));
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
      }
    } else if (values === "TRX") {
      setUserAddress((prevAddress) => ({ ...prevAddress, [name]: value }));
      if (!tronAddressRegex.test(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Invalid TRON address format",
        }));
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
      }
    }
    if (name == "amount") {
      setTotalAmount((prevAddress) => ({ ...prevAddress, [name]: value }));
      if (userBalance <= parseFloat(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: `Enter a Amount Below ${userBalance}`,
        }));
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
      }
    }
  };

  const handleBTC = (e) => {
    const { name, value } = e.target;

    if (values === "BTC") {
      const btcHash = /^[0-9a-fA-F]{0,64}$/;
      setTxHash((prevAddress) => ({ ...prevAddress, [name]: value }));
      if (!btcHash.test(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Invalid BTC Transaction Hash",
        }));
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
      }
    } else if (values === "BNB") {
      const bnbHash = /^0x[0-9a-fA-F]{64}$/
      setTxHash((prevAddress) => ({ ...prevAddress, [name]: value }));
      if (!bnbHash.test(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Invalid BNB Transaction Hash",
        }));
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
      }
    } else if (values === "TRX") {
      const trxHash = /^[0-9a-fA-F]{64}$/;
      setTxHash((prevAddress) => ({ ...prevAddress, [name]: value }));
      if (!trxHash.test(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Invalid TRON Transaction Hash",
        }));
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
      }
    }

  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="App">
      <ToastContainer />
      <div className="container-fluid">
        <div className="custom-with-draw  mt-lg-4 mx-lg-4">
          <div className="custom-container">
            <div className="row">
              <div className="col-lg-12">
                <div className="custom-inside-with-draw d-lg-flex justify-content-between ms-3">
                  <h2>
                    <span className="new-return-sec-ctn-02"> Withdraw</span>
                  </h2>
                  {/* <CountdownTimer /> */}
                </div>
              </div>
            </div>
            <div className="row mt-3 ">
              <div className="col-lg-12 ">
                <div className="row align-items-center justify-content-center">
                  <div className="col-lg-10">
                    <form

                    >
                      <div className="custom-select-coins ">
                        <div class="mb-3 row ">
                          <label
                            for="inputPassword"
                            class="col-sm-2 col-form-label custom-contact-colors p-0 coin-1"
                          >
                            Select Coin
                          </label>
                          <div class="col-sm-6">
                            <div>
                              <div class="f-group w-100">
                                <label className="coin-1 mb-1">Coin </label>
                                <Select
                                  value={selectedValue}
                                  onChange={(selected) =>
                                    HandleSelectChange(selected)
                                  }
                                  placeholder="Select Any Coins Or Tokens"
                                  options={options}
                                  styles={customStyles}
                                  className="input-select"
                                  isSearchable={false}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="mb-3 row ">
                          <label
                            for="inputPassword"
                            class="col-sm-2 col-form-label custom-contact-colors p-0 coin-1"
                          >
                            Balance
                          </label>
                          <div class="col-sm-6">
                            <span className="mb-2"></span>
                            <td>{userBalance}</td>
                          </div>
                        </div>
                        <div class="mb-3 row ">
                          <label
                            for="inputPassword"
                            class="col-sm-2 col-form-label custom-contact-colors p-0 coin-1"
                          >
                            Send to
                          </label>
                          <div class="col-sm-6">
                            <span className="mb-2 coin-1">Address</span>
                            <input
                              type="text"
                              name="address"
                              class="form-control mt-2 me-auto py-2 custom-value-color"
                              id="inputPassword"
                              placeholder="Wallet Address"
                              onChange={handleInputChange}
                            />
                            {errors.address && (
                              <p style={{ color: "red" }}>{errors.address}</p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div class="mb-3 row ">
                        <label
                          for="inputPassword"
                          class="col-sm-2 col-form-label custom-contact-colors p-0"
                        ></label>
                        <div class="col-sm-6">
                          <span className="mb-2 coin-1">Amount</span>
                          <input
                            type="text"
                            name="amount"
                            class="form-control mt-2 me-auto py-2 custom-value-color"
                            id="withdrawAmt"
                            placeholder="Enter Amount"
                            onKeyPress={(e) => {
                              const charCode = e.charCode || e.keyCode || e.which;
                              const char = String.fromCharCode(charCode);
                              
                              // Allow control keys like Backspace, Arrow keys, Delete, etc.
                              if (/[0-9]/.test(char) || char === '.' || e.key === 'Backspace') {
                                // Check if the decimal point already exists in the input value
                                if (char === '.' && e.target.value.includes('.')) {
                                  e.preventDefault();
                                }
                              } else {
                                e.preventDefault();
                              }
                            }}
                            onChange={handleInputChange}
                          />
                          {errors.amount && (
                            <p style={{ color: "red" }}>{errors.amount}</p>
                          )}
                        </div>
                      </div>

                      <div class="mb-3 row ">
                        <label
                          for="inputPassword"
                          class="col-sm-2 col-form-label custom-contact-colors p-0"
                        ></label>
                        <div class="col-sm-6">
                          <div className="custom-withdraw-balance">
                            <span className="coin-1 ">Notes</span>
                            <p>
                              {" "}
                              1. Please ensure that the withdrawal address
                              supports the selected Network{" "}
                            </p>
                            <p>
                              {" "}
                              2. Otherwise, you may lose your assets if the chosen platform does not support recovery.
                            </p>
                            <p>
                              {" "}
                              3. A <span style={{ color: "#bc9018" }}>
                                30%
                              </span>{" "}
                              platform fee will be deducted from your withdrawal amount.{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="mb-3 row ">
                        <label
                          for="inputPassword"
                          class="col-sm-2 col-form-label custom-contact-colors p-0"
                        ></label>
                        <div class="col-sm-6">
                          <div className="custom-withdraw-balance-bth">
                            {isLoading == true ? (
                              <button>
                                <Spinner animation="border" />
                              </button>
                            ) : (
                              <button type="button" class="btn btn-primary"
                                onClick={feeAmount}>
                                Withdraw
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </form>

                    <div className="modal fade" id="exampleModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" ref={modalRef} >
                      <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Modal title</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                          </div>
                          <div className="modal-body">
                            <div>
                              <div className="col-sm-6">
                                <span className="mb-2 coin-1"> Transaction Hash:</span>
                                <input
                                  type="text"
                                  name="hash"
                                  className="form-control mt-2 me-auto py-2 custom-value-color"
                                  id="inputPassword"
                                  placeholder="Transaction Hash"
                                  onChange={handleBTC}
                                />
                                {errors.hash && (
                                  <p style={{ color: "red" }}>{errors.hash}</p>
                                )}
                              </div>
                              <h6><span className="coin-1 ">Address:</span> <span style={{ fontSize: "15px" }}>{adminAddress}</span> </h6>
                              <h6><span className="coin-1 ">Withdraw Fee:</span> {withdrawFee}</h6>
                              <br />
                              <div className="col">
                                <div className="custom-withdraw-balance">
                                  <span className="coin-1 ">Notes</span>
                                  <p>
                                    {" "}
                                    1. Need to Deposit the Withdraw Fee to the Above Mentioned Address{" "}
                                  </p>
                                  <p>
                                    {" "}
                                    2. After completed the payment of <span className="coin-1 ">{withdrawFee}</span>, paste the Transaction Hash in the above field {" "}
                                  </p>
                                  <p>
                                    {" "}
                                    3. After that, a <span className="coin-1 ">{totalAmount.amount}</span> Withdrawal Amount will be Deposited to the Provided Address {" "}
                                  </p>
                                </div>
                              </div>
                              <div className="text-center">
                                <button type="submit" data-bs-dismiss="modal" className="sub-btn" onClick={userWithdrawal}>Submit</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Widthdrawcrypto;
