import React, { useRef, useState, useEffect } from "react";

import darklogo from "../../Assets/images/logodarktheme.png";
import whitelogo from "../../Assets/images/logo-whitetheme.png";
import login from "../../Assets/images/w-login.png";
import { makeApiRequest } from '../../config/AxiosService'
import { FaEye } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useFormik, } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { HiOutlineDownload } from "react-icons/hi";
import Apk from "../../pages/WillGrow.apk";
import Theme from "./Theme";

export default function Navbar() {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const token = localStorage.getItem("token")
  const navigate = useNavigate()

  // register

  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    touched,
    errors,
    values,
    setFieldValue,
    resetForm,
    setValues
  } = useFormik({
    initialValues: {
      email: "",
      password: "",
      termsAndConditions: false,

    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email("Please enter valid email!").required("E-mail is required!").matches(regex, 'Invalid Email!'),
      password: Yup.string().required(" Password is required!"),
      termsAndConditions: Yup.bool().oneOf([true], 'You need to accept the terms and conditions'),

    }),
    onSubmit: async (values) => {
      try {
        const payload = {
          email: values.email,
          password: values.password
        };
        let params = {
          url: `register`,
          method: "POST",
          data: payload
        }
        const res = await makeApiRequest(params)

        if (res.status === false) {
          toast.error(res.message)
        }
        else {
          setTimeout(() => {
            if (res.status == true) {
              toast.success(res.message)
            }
          }, 1000);

          setTimeout(() => {
            if (res.status == true) {
              window.location.reload()
            }
          }, 2000);
        }
      } catch (error) {
        console.log("error", error.message);
      }
    },
  });


  // login

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (emailError) setEmailError('');
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    if (passwordError) setPasswordError('');
  };

  const handleclick = async (e) => {
    e.preventDefault();

    setEmailError('');
    setPasswordError('');

    // Email validation
    if (!email && !password) {
      setEmailError('Email is required');
      setPasswordError('Password is required');
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError('Please enter a valid email!');
    } else if (!email) {
      setEmailError('Email is required');
    } else if (!password) {
      setPasswordError('Password is required');
    }
    else {
      const payload = {
        email: email,
        password: password
      };
      let params = {
        url: `userLogin`,
        method: "POST",
        data: payload
      }

      try {
        const res = await makeApiRequest(params)
        if (res.status === false) {
          toast.error(res.message)
        }
        else {
          setTimeout(() => {
            if (res.status == true) {
              toast.success(res.message)
            }
          }, 1000);

          setTimeout(() => {
            if (res.status == true) {
              window.location.reload()
            }
          }, 2000);
        }
      } catch (error) {
        console.log(error.message);
      }
    }

  }

  const handleClose = async () => {
    setEmail("")
    setEmailError("")
    setPassword("")
    setPasswordError("")
  }

  return (
    <>
      <div className="navbar-section">
        <section>
          {/* <nav class="navbar bg-body-transparent position-fixed">
            <div class="container">
              <a class="navbar-brand darklogo">
                <img src={darklogo} className="logo " alt="logo" />
              </a>
              <a class="navbar-brand whitelogo">
                <img src={whitelogo} className="logo " alt="logo" />
              </a>
              <div class="d-flex gap-2">
                <button class="signup-btn" type="submit">
                  Sign Up
                </button>
                <button class="login-btn" type="submit">
                  Login
                </button>
              </div>
            </div>
          </nav> */}
          <nav class="navbar navbar-expand-lg pb-lg-5">
            <div class="container-fluid">
              <a class="navbar-brand whitelogo  ms-lg-5"><img src={darklogo} className="logo " alt="logo" /></a>
              <a class="navbar-brand darklogo ms-lg-5"><img src={whitelogo} className="logo " alt="logo" /></a>
              <button
                class="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarScroll"
                aria-controls="navbarScroll"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span class="navbar-toggler-icon"></span>
              </button>
              <div class="collapse navbar-collapse" id="navbarScroll">
                <ul class="navbar-nav ms-auto my-2 my-lg-0 navbar-nav-scroll align-items-center gap-3">
                  <li class="nav-item">
                    <a href={Apk} download="WillGrow.apk" >
                      <button
                        class="log-btn "
                        type="submit"

                      >
                        Download  <HiOutlineDownload />
                      </button>
                    </a>
                    {/*                   
                    <a className="download-button ms-lg-5" href={Apk} download>Download</a> */}
                  </li>
                  {token ? (
                    <li class="nav-item">
                      <a href="/dashboard/profile" >
                        <button
                          class=" sign-btn "
                          type="submit"
                        >
                          Dashboard
                        </button>
                      </a>
                    </li>
                  ) : (
                    <>
                      <li class="nav-item">
                        <a href="/register" >
                          <button
                            class="sign-btn "
                            type="submit"
                          >
                            Sign Up
                          </button>
                        </a>
                      </li>

                      <li class="nav-item">
                        <a href="/login" >
                          <button
                            class=" sign-btn "
                            type="submit"
                          >
                            Login
                          </button>
                        </a>
                      </li>
                    </>
                  )}

                  <li class="nav-item dropdown">
                    <Theme />
                  </li>
                </ul>
              </div>
            </div>
          </nav>

          <div
            class="modal fade"
            id="staticBackdrop"
            data-bs-backdrop="static"
            tabindex="-1"
            data-bs-keyboard="false"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content" id="staticBackdropLabel">
                <div class="modal-body">
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => handleClose()}></button>
                  <form className="m-3" onSubmit={handleclick} autoComplete="off" >
                    <div className="text-center">
                      <img src={login} className="login-logo" />
                      <h3 className="banner-color"> Login </h3>
                    </div>

                    <div class="mb-3">
                      <label for="exampleInputEmail1 " class="form-label banner-color">
                        Email address
                      </label>
                      <input
                        type="email"
                        placeholder="email"
                        class="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        value={email}
                        onChange={handleEmailChange}
                      />
                      {emailError && <div className="error">{emailError}</div>}
                    </div>
                    <div class="mb-3">
                      <label for="exampleInputPassword1" class="form-label banner-color">
                        Password
                      </label>
                      <div className="d-flex position-relative align-items-center">
                        <input
                          type="password"
                          class="form-control"
                          id="exampleInputPassword1"
                          placeholder="password"
                          value={password}
                          onChange={handlePasswordChange}
                        />
                        <FaEye style={{ marginLeft: "-30px" }} className="banner-color" />
                      </div>
                      {passwordError && <div className="error">{passwordError}</div>}
                    </div>
                    <div class="mb-3 text-center banner-color">
                      <p>Forgot Password ?</p>
                    </div>
                    <div class="mb-3 text-center">
                      <button
                        class="login-btn"
                        type="submit"
                      >
                        Login
                      </button>
                    </div>
                    <div className="d-flex justify-content-center align-items-center">
                      <p className="mb-0 banner-color">Don't have an account?</p>
                      <a
                        class="acc-link ps-2"
                        data-bs-toggle="modal"
                        data-bs-target="#staticBackdrop1"
                      >
                        Sign up
                      </a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div
            class="modal fade"
            id="staticBackdrop1"
            data-bs-backdrop="static"
            tabindex="-1"
            data-bs-keyboard="false"
            aria-labelledby="staticBackdropLabel1"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content" id="staticBackdropLabel1">
                <div class="modal-body">
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => resetForm()}></button>
                  <form className="m-3" onSubmit={handleSubmit} autoComplete="off" >
                    <div className="text-center">
                      <img src={login} className="login-logo" />
                      <h3 className="banner-color"> Sign up </h3>
                    </div>

                    <div class="mb-3">
                      <label for="exampleInputEmail1" class="form-label banner-color">
                        Email address
                      </label>
                      <input
                        type="email"
                        placeholder="email"
                        class="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        onChange={handleChange('email')}
                        value={values.email}
                      />{touched.email && errors.email ? <div className="error">{errors.email}</div> : null}
                    </div>
                    <div class="mb-3">
                      <label for="exampleInputPassword1" class="form-label banner-color">
                        Password
                      </label>
                      <div className="d-flex position-relative align-items-center">
                        <input
                          type="password"
                          class="form-control"
                          id="exampleInputPassword1"
                          placeholder="password"
                          onChange={handleChange('password')}
                          value={values.password}
                        />
                        <FaEye style={{ marginLeft: "-30px" }} className="banner-color" />
                      </div>
                      {touched.password && errors.password ? <div className="error">{errors.password}</div> : null}
                    </div>
                    <div class="form-check mb-3">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                        checked={values.termsAndConditions}
                        onChange={() => setFieldValue('termsAndConditions', !values.termsAndConditions)}
                      />
                      <label class="form-check-label banner-color" for="flexCheckDefault">
                        Terms & Conditions
                      </label>
                    </div>
                    <div class="mb-3 text-center">
                      <button
                        class="login-btn"
                        type="submit"
                      >
                        Sign up
                      </button>
                    </div>
                    <div className="d-flex justify-content-center align-items-center">
                      <p className="mb-0 banner-color">Already have an account?</p>
                      <a
                        class="acc-link ps-2"
                        data-bs-toggle="modal"
                        data-bs-target="#staticBackdrop"
                      >
                        Login
                      </a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div >
    </>
  );
}
