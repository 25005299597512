import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import { useLocation, useParams } from 'react-router-dom';
import moment from "moment";

function RewardData() {
    const location = useLocation()
    const { datas } = location.state
    const { id } = useParams();

    const [ userData, setUserData ] = useState()

    const rewardData = async() => {
        const totalData = datas.deposit_reward
        setUserData(totalData)
    }

    useEffect(()=>{
        rewardData()
    },[])

    const columns = [
        {
            name: 'S.No',
            selector: (row, index) => index + 1,
            sortable: false,
            width: '70px'
        },
        {
            name: 'Date',
            selector: row => moment(row.credited_date).format('MMMM Do YYYY, h:mm:ss a'),
            sortable: true,
        },
        {
            name: 'Reward Amount',
            selector: row => row.reward,
            sortable: true,
        },
    ];

  return (
    <div>
       <div className="App refer-section">
            <div className="container-fluid" style={{ marginLeft: "15px" }}>
                <div className="custom-saled-order text-dark">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="py-3 justify-content-center d-flex justify-content-between">
                                <h3 className="component-user text-center ms-lg-0">Reward History</h3>
                            </div>
                            <div className="custom-inside-saled-order text-center mt-5">
                                <div className="tab-content" id="pills-tabContent">
                                    <h4 className="component-user text-center ms-lg-0" style={{display: "flex"}}> Total Reward:-  {datas.interestAmnt} {datas.network == "BTC" ? "BTC" : "USDT"} </h4>
                                    <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabIndex="0">
                                        <div className="custom-datascroll">
                                            <DataTable
                                                columns={columns}
                                                data={userData}
                                                pagination
                                                persistTableHead
                                                paginationPerPage={5}
                                                paginationRowsPerPageOptions={[5, 10, 15, 20]}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default RewardData