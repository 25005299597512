import React, { useEffect } from 'react'
import { useState } from 'react';
import QRCode from "react-qr-code";
import Select from 'react-select';
import Spinner from "react-bootstrap/Spinner";
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import { PiCopySimpleFill } from 'react-icons/pi';
import { Tooltip } from 'react-tooltip';
import { makeApiRequest } from "../../config/AxiosService";

function Deposite() {
    const location = useLocation()
    const Walletcoin = location.state ? location.state.coin : null;
    const [startDate, setStartDate] = useState(new Date());
    const currTime = new Date().toLocaleTimeString();
    const [NetworkAddress, setNetworkAddress] = useState(null)
    const [symbol, setSymbol] = useState()
    const [copied, setCopied] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const UserEmail = localStorage.getItem('email')
    const [addressLoader, setAddressLoader] = useState(false);
    const [selectedValue, setSelectedValue] = useState();
    const [value, setValue] = useState();
    const navigate = useNavigate()

    const customStyles = {
        menu: base => ({
            ...base,
            background: "var(--background-color-3)",
            margin: "0px",
            padding: "0px",
            color: "var(--color-1)",
        }),
        control: (base, state) => ({
            ...base,
            background: "transparent",
            color: "#000",
        }),
        option: (base, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...base,
                backgroundColor: isFocused ? "#F8C702" : "transparent",
                color: "black"
            };
        }
    };

    const options = [
        {
            value: 'BTC', Symbol: 'btcusdt', label: <a><div className='select-color'>
                <img src={"https://s2.coinmarketcap.com/static/img/coins/200x200/1.png"} className=" me-2" style={{ width: "20px", color: "black" }} />Bitcoin
            </div></a>
        },

        {
            value: 'BNB', Symbol: 'bnbusdt', label: <a><div className='select-color' >
                <img src={"https://bscscan.com/token/images/busdt_32.png"} className=" me-2" style={{ width: "20px", color: "black" }} />BEP20
            </div></a>
        },
        // {
        //     value: 'BNB', Symbol: 'bnbusdt', label: <a><div style={{ color: "black" }}>
        //         <img src={"https://cryptologos.cc/logos/bnb-bnb-logo.png"} className=" me-2" style={{ width: "20px" }} /> BNB
        //     </div></a>
        // },
        {
            value: 'TRX', Symbol: 'trxusdt', label: <a><div className='select-color'>
                <img src={'https://cdn-icons-png.flaticon.com/512/12114/12114250.png'} className='  me-2' style={{ width: "20px", color: "black" }} />TRC20
            </div></a>
        },
    ];

    const HandleSelectChange = (e) => {
        setValue(e.value);

        const value = e.value;
        options.map((data) => {
            if (value === data.value) {
                setSelectedValue(data)
                setSymbol(data.Symbol)
            }
        })
    }

    const coinName = () => {
        if (Walletcoin == "BTC") {
            const btcOption = options.find(option => option.value === "BTC");
            setValue(btcOption.value);
            setSelectedValue(btcOption);
            setSymbol(btcOption.Symbol);
        }
        else if (Walletcoin == "BNB") {
            const bnbOption = options.find(option => option.value === "BNB");
            setValue(bnbOption.value);
            setSelectedValue(bnbOption);
            setSymbol(bnbOption.Symbol);
        }
        else if (Walletcoin == "TRX") {
            const TronOption = options.find(option => option.value === "TRX");
            setValue(TronOption.value);
            setSelectedValue(TronOption);
            setSymbol(TronOption.Symbol);
        }
    }

    const Depositaddress = async () => {
        try {
            setAddressLoader(true)
            let params = {
                url: `getUserProfile?UserEmail=${UserEmail}&selectedValue=${value}`,
                method: "GET",
            }

            const response = await makeApiRequest(params);
            if (response.status) {
                const Address = response.walletAddress;
                setNetworkAddress(Address);
                setAddressLoader(false)
            } else {
                setAddressLoader(true)
            }
        } catch (error) {
            setAddressLoader(true)
            console.log('Error fetching address:', error.message);
        }
    };

    const DepositTransaction = async () => {
        setIsLoading(true)
        try {
            if (!value) {
                toast.error('Please select a network');
                setIsLoading(false)
                return
            }
            const payload = {
                UserEmail: UserEmail,
                network: value
            }
            let params = {
                url: `depositTransactions`,
                method: "POST",
                data: payload
            };
            const depositCoin = await makeApiRequest(params)
                if (depositCoin.status == true) {
                    toast.success(depositCoin.message)
                    setIsLoading(false)
                    navigate("/dashboard/history")
                } else {
                    toast.warn(depositCoin.message)
                    setIsLoading(false)
                }
        } catch (error) {
            toast.error('Something went wrong');
            setIsLoading(false)
        }
    }

    useEffect(() => {
        Depositaddress();
        coinName()
    }, [selectedValue, Walletcoin]);

    const copyText = () => {
        navigator.clipboard.writeText(NetworkAddress)
            .then(() => {
                setCopied(true);
                setTimeout(() => {
                    setCopied(false);
                }, 2000);
            })
            .catch(err => {
                console.error('Unable to copy secret to clipboard:', err);
            });
    };

    return (
        <div className='App'>
            <ToastContainer />
            <div className='container-fluid'>
                <div className='custom-deposite-wallet mt-lg-4 mx-lg-4'>
                    <div className='custom-container'>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className='custom-inside-deposite custom-inside-with-draw d-flex justify-content-between'>
                                    <h2><span className='new-Deposite-sec-ctn-02'> Deposit crypto</span></h2>

                                </div>
                            </div>
                        </div>

                        <div className='row mt-2 '>
                            <div className='col-lg-12 '>
                                <div className='row align-items-center justify-content-center'>
                                    <div className='col-lg-11'>
                                        <div className='custom-select-coins '>
                                            {/* {emailVerifyStatus == 0 ?
                                                (<h5 className="verify-email">{"Verify your Email First"}
                                                    <a href="/dashboard/profile" className="ps-lg-2" style={{ color: "red", fontSize: "16px" }}>ClickHere</a>
                                                </h5>)
                                                : (
                                                    <></>
                                                )} */}
                                            <div class="mb-0 row ">
                                                <label for="inputPassword" class="col-sm-2 col-form-label custom-contact-colors p-0"></label>
                                                <div class="col-sm-5 p-0">
                                                    <div>
                                                        <div class="f-group w-100">
                                                            <label>Select Network </label>

                                                            <Select
                                                                value={selectedValue}
                                                                onChange={(selected) => HandleSelectChange(selected)}
                                                                placeholder="Please Select Coin"
                                                                options={options}
                                                                styles={customStyles}
                                                                className='input-select'
                                                                isSearchable={true}
                                                            />


                                                        </div><br />
                                                    </div>
                                                </div>

                                            </div>

                                            <div class="mb-3 row custom-deposite-loader-1">
                                                <label for="inputPassword" class="col-sm-2 col-form-label custom-contact-colors p-0"></label>
                                                {

                                                    addressLoader ? (<div class="col-sm-3 text-center"><Spinner animation="border" className='deposite-spinner' /><p style={{ color: "#BC9018", fontSize: "14px", marginTop: "10px" }}>Select Any Coin To Show The Address</p></div>)
                                                        : (
                                                            <div class="col-sm-6">
                                                                <div className='custom-scane-data text-left mt-3'>
                                                                    {NetworkAddress ? (
                                                                        <QRCode
                                                                            size={256}
                                                                            style={{ height: "auto", maxWidth: "25%", width: "25%" }}
                                                                            value={NetworkAddress}
                                                                            viewBox={`0 0 256 256`}
                                                                            className='new-qr-code-btn-01'
                                                                        />

                                                                    ) : (
                                                                        <p>Select Any Coin To Show The Address</p>

                                                                    )}

                                                                    {/* <p>Address:{NetworkAddress}</p> */}
                                                                    <div>
                                                                        <button className='address-1-btn mt-4 new-btn-ctn-01 qr-code-back' onClick={copyText}> {NetworkAddress}<a data-tooltip-id="my-tooltip-click"><PiCopySimpleFill className='ms-3' /></a></button>

                                                                        <Tooltip
                                                                            id="my-tooltip-click"
                                                                            content="Copied!"
                                                                            events={['click']}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="row align-items-end">
                                                                    <div className="col-lg-12 mt-4 text-left ">
                                                                        <div className=''>
                                                                            <div className='d-flex'>
                                                                                <div className='custom-withdraw-balance'>
                                                                                    <span className='coin-1 '>Notes</span>
                                                                                    <p> 1. Please select the coin you wish to deposit. </p>
                                                                                    <p> 2. The Minimum Deposited Amount for <span style={{ color: "#bc9018" }}>{value}</span> will be <span style={{ color: "#bc9018" }}>{value == "BTC" ? "0.0001 BTC" : value == "BNB" || value == "TRX" ? "100 USDT" : "100" }</span>. </p>
                                                                                    <p> 3. Your deposited amount will be locked for <span style={{ color: "#bc9018" }}>45 days</span>. You can withdraw it only after this period.</p>
                                                                                    <p> 4. Rewards will be added every <span style={{ color: "#bc9018" }}>24 hours</span>.</p>
                                                                                </div>

                                                                            </div>


                                                                        </div>
                                                                        <div className="custom-calender-btn text-center mt-4">

                                                                            {isLoading == true ? (
                                                                                <button ><Spinner animation="border" /></button>
                                                                            ) : (
                                                                                <button onClick={() => { value == "TRX" ? setTimeout(() => { DepositTransaction() }, 40000) : DepositTransaction() }} >
                                                                                    Submit
                                                                                </button>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        )
                                                }
                                            </div>
                                            {/* <div class="mb-3 row ">

                                                <div class="col-sm-8">
                                                    <div className='custom-deposite-address'>
                                                        <BsDot /> Send only <span>1IINCH-ERC20</span> to this <br />deposite address.
                                                    </div>
                                                </div>

                                            </div> */}
                                            {/* <div class="mb-3 row ">

                                                <div class="col-sm-8">
                                                    <div className='custom-recent-deposit'>
                                                        <h2>Recent Deposits</h2>
                                                        <p>if your previous deposit is not reflecting, you can claim your deposit below</p>
                                                        <h5>CLAIM DEPOSIT ?</h5>
                                                    </div>
                                                </div>

                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className='custom-withdraw-table mt-4'>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <div className='custom-with-scroll'>
                                        <table class="table w-100">
                                            <thead >
                                                <tr>
                                                    <th >Assets</th>

                                                    <th >Amount</th>
                                                    <th >Bonus</th>
                                                    <th ></th>
                                                    <th ></th>
                                                    <th >Fees</th>

                                                    <th >Status</th>
                                                    <th >Date</th>


                                                </tr>
                                            </thead>

                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        {/* <div className='row'>
                            <div className='col-lg-12'>
                                <div className='custom-wallet-search'>
                                    <HiOutlineDocumentSearch />
                                    <h2 className='mt-2'>No Records Found</h2>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div >


    )
}


export default Deposite