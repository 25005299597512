import React from "react";
import SwiperCore from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import cardimg from "../Assets/images/blogone.jpg";
import { TfiLayersAlt } from "react-icons/tfi";
import btc from "../Assets/images/Bitcoin.webp";
import ustd from "../Assets/images/ustd.png";
import { CiCalendar } from "react-icons/ci";
// import card1 from "../Assets./"
import { FaRegClock } from "react-icons/fa";
import { FaGift } from "react-icons/fa6";
import card1 from "../Assets/images/card1.png";
import card2 from "../Assets/images/card2.png";
import card3 from "../Assets/images/card3.png";
import { FaApple } from "react-icons/fa";
import { IoLogoGooglePlaystore } from "react-icons/io5";
import jQuery from "jquery";
import { useRef, useState, useEffect } from "react";
import Apk from "../pages/WillGrow.apk";
import { IoIosArrowForward } from "react-icons/io";
import { FaArrowTrendUp } from "react-icons/fa6";
import { HiMiniArrowTrendingUp } from "react-icons/hi2";
import phonebanner from "../Assets/images/phonebanner.png";
import Navbar from "./separate/Navbar";
import { useNavigate } from "react-router-dom";
import config from "../config/Config"
import socketIOClient from "socket.io-client"


import btcIcon from '../Assets/images/Bitcoin.webp';
import daiIcon from '../Assets/images/Bitcoin.webp';
import ethIcon from '../Assets/images/Bitcoin.webp';
import usdtIcon from '../Assets/images/Bitcoin.webp';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

export default function Home() {
  const [value, setValue] = useState(0);
  const [socketConnection, setSocketConnection] = useState(null);
  const [coinsData, setCoinsData] = useState([]);
  const [btcPrice, setBtcPrice] = useState(0);
  const [bnbPrice, setBnbPrice] = useState(0);
  const [trxprice, setTrxPrice] = useState(0);
  const [solanaPrice, setSolanaPrice] = useState(0);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const bnbIcon = "https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png"
  const trxIcon = "https://s2.coinmarketcap.com/static/img/coins/64x64/1958.png"

  const [selected, setSelected] = useState({
    value: 'BTC',
    icon: btcIcon,
    roi: '~10.00%'
  });
  const [isOpen, setIsOpen] = useState(false);
  const [symbols, setSymbols] = useState({
    holdings: 0,
    coinValue: 0,
    dailyProfit: "",
    monthlyProfit: 0,
    yearlyProfit: 0,
    DailyYield: ""
  })



  const Navigate = useNavigate();

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    setIsLoggedIn(!!storedToken);
  }, []);

  useEffect(() => {
    const socket = socketIOClient(config.backendurl, {
      transports: ['websocket'],
    });

    socket.on('connect', () => {
      console.log('Socket connected');
      setSocketConnection(socket);
    });

    socket.on('disconnect', () => {
      console.log('Socket disconnected');
    });

    return () => {
      if (socket) {
        socket.disconnect();
        console.log('Socket disconnected on cleanup');
      }
    };
  }, []);

  useEffect(() => {
    if (socketConnection) {
      socketConnection.on('TotalDataPrice', (data) => {

        if (typeof data === 'string') {
          try {
            data = JSON.parse(data);
          } catch (error) {
            console.error('Error parsing JSON data:', error);
            return;
          }
        }

        if (Array.isArray(data)) {

          // const filteredData = data.filter(item => item.symbol === 'BTCUSDT' || item.symbol === 'BNBUSDT' || item.symbol === 'TRXUSDT' || item.symbol === 'SOLVEUSDT');
          // let afterRemoveUnusedParameter = data.map(({ __v, _id, ask, bid, change_24h, highest_price, lowest_price, time, volume, ...rest }) => rest);
          setCoinsData(data);
          
          // filteredData.forEach(item => {
          //   if (item.symbol === 'BTCUSDT') {
          //     setBtcPrice(item.current_price);
          //   }
          //   if (item.symbol === 'BNBUSDT') {
          //     setBnbPrice(item.current_price);
          //   }
          //   if (item.symbol === 'TRXUSDT') {
          //     setTrxPrice(item.current_price);
          //   }
          //   if (item.symbol === 'SOLVEUSDT') {
          //     setSolanaPrice(item.current_price);
          //   }
          // });
        } else {
          console.log('Data is not an array:', data);
        }
      });
    }
  }, [socketConnection, coinsData]);

  const handleStakeNowClick = () => {
    if (isLoggedIn) {
      Navigate('/dashboard/deposit');
    } else {
      Navigate('/login');
    }
  };



  useEffect(() => {
    var accordions = document.querySelectorAll(".accordion-item");

    accordions.forEach(function (accordion) {
      accordion.addEventListener("click", function () {
        // Close all accordions
        accordions.forEach(function (acc) {
          acc.querySelector(".accordion-collapse").classList.remove("show");
        });

        // Open the clicked accordion
        this.querySelector(".accordion-collapse").classList.add("show");
      });
    });
  });

  const options = [
    { value: 'BTC', label: 'BTC', icon: btcIcon, roi: '~10.00%' },
    { value: 'BNB', label: 'BNB', icon: bnbIcon, roi: '~10.00%' },
    { value: 'TRX', label: 'TRX', icon: trxIcon, roi: '~10.00%' },
  ];

  const handleValue = async() => {
    if(selected.value == "BTC") {
      setSymbols({holdings: 10, coinValue: 1.22, dailyProfit: "0.00000033", 
        monthlyProfit: 0.00001, yearlyProfit: 0.012, DailyYield: "10.00"})
    } else if(selected.value == "BNB") {
      setSymbols({holdings: 0.004, coinValue: 2.29, dailyProfit: "0.00000033", 
        monthlyProfit: 0.00001, yearlyProfit: 0.00012, DailyYield: "10.00"})
    } else if (selected.value == "TRX") {
      setSymbols({holdings: 10, coinValue: 1.33, dailyProfit: "0.00136986", 
        monthlyProfit: 0.04166667, yearlyProfit: 0.5, DailyYield: "10.00"})
    }
  }

  useEffect(()=>{
   handleValue() 
  })

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelected(option);
    setIsOpen(false);
  };

  return (
    <>
      <div className="home-section">
        <section className="home-section-one">
          <div className="container">
            <div className="row home-sec1-height flex-lg-row-reverse flex-md-row-reverse  pb-4 pb-lg-0">
              <div className="col-md-5  mt-5 d-flex justify-content-center">
                <img src={phonebanner} className="phonebanner" />
              </div>
              <div className="col-lg-7 col-md-7">
                <h3 className="hm-tx banner-color">
                  Stake & Earn daily 10% Reward
                </h3>
                <h5 className="hm-tx-2 banner-color">
                  {" "}
                  on BTC, USDT and Other Assets
                </h5>
                <p className="hm-sec1-tx3 banner-color">
                  Grow Your crypto wealth and keep it secure with Will Grow
                </p>
                <div className="d-flex">
                  <button class="get-start-btn " type="submit">
                    Get Started{" "}
                    <IoIosArrowForward className="new-ups-button-icon-1" />
                  </button>
                  <button class="get-start-btn2" type="submit">
                    <FaApple style={{ fontSize: "22px" }} />
                  </button>
                  <button class="get-start-btn2" type="submit">
                    <IoLogoGooglePlaystore style={{ fontSize: "22px" }} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="home-section-two">
          <div className="container">
            <Swiper
              slidesPerView={1}
              spaceBetween={0}
              navigation={false}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              breakpoints={{
                640: {
                  slidesPerView: 1,
                  spaceBetween: 30,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 30,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
              }}
              modules={[Autoplay, Pagination, Navigation]}
              className="py-5"
            >
              <SwiperSlide>
                <img src={card2} alt="home" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={card1} alt="home" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={card3} alt="home" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={card1} alt="home" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={card2} alt="home" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={card1} alt="home" />
              </SwiperSlide>
            </Swiper>
          </div>
        </section>

        <section className="home-section-three mb-3">
          <div className="container sec-three-background">
            <div className="row row-cols-lg-2 row-cols-1 sec-three-background-color py-lg-5 px-lg-4 py-3">
              <div className="col col-md-5 ">
                <div>
                  <TfiLayersAlt className="layer-btn my-3" />
                  <h3 className="hm-tx-3">
                    Unlock the full potential of your crypto assets through
                    staking. Start earning your rewards today
                  </h3>
                  <button class="login-btn py-lg-3 mt-3" type="submit">
                    Stake now
                  </button>
                </div>
              </div>
              <div className="col pt-lg-0 pt-3 col-md-7">
                <div className="card p-lg-3">
                  <div className="card-body">
                    <div className="row sec3-card-border p-3">
                      <div className="col-lg-7 col-7 d-flex justify-content-between">
                        <div>
                          <h5 className="hm-sec3-tx1">Your holdings</h5>
                          <h3 className="hm-sec3-tx2">{symbols.holdings}</h3>
                        </div>
                        <div>
                          <h5 className="hm-sec3-tx1">Values</h5>
                          <h3 className="hm-sec3-tx2">${symbols.coinValue}</h3>
                        </div>
                      </div>
                      <div className="col-lg-5 col-5 d-flex justify-content-center gap-2 align-items-center btc-sec">
                        {/* <div>
                          <img src={btc} className="btc-img" alt="home" />
                        </div>
                        <div>
                          <h5 className="hm-sec3-tx1">BTC</h5>
                          <h3 className="hm-sec3-tx2">ROI ~3.00%</h3>
                        </div> */}
                        <div className="dropdown" onClick={toggleDropdown}>
                          <div className="dropdown-selected">
                            <div className="dropdown-selected-left">
                              <img src={selected.icon} alt={selected.value} />
                              <span>{selected.value} ROI {selected.roi}</span>
                            </div>
                            <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} className="dropdown-icon" />
                          </div>
                          {isOpen && (
                            <div className="dropdown-options">
                              {options.map(option => (
                                <div
                                  key={option.value}
                                  className="dropdown-option"
                                  onClick={() => handleOptionClick(option)}
                                >
                                  <img src={option.icon} alt={option.label} />
                                  <span>{option.label} ROI {option.roi}</span>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center pt-2 disable">
                      <input
                        type="range"
                        class="form-range"
                        id="disabledRange"
                        disabled
                        value={value}
                      />
                    </div>
                    <div className="row row-cols-2 pt-3">
                      <div className="col pe-2">
                        <div className="card p-3 card-back h-100">
                          <h5 className="hm-sec3-tx1">Daily Profit</h5>
                          <h3 className="hm-sec3-tx2">{symbols.dailyProfit} {selected.value}</h3>
                        </div>
                      </div>
                      <div className="col ps-0">
                        <div className="card p-3 card-back h-100">
                          <h5 className="hm-sec3-tx1">Monthly Profit</h5>
                          <h3 className="hm-sec3-tx2">{symbols.monthlyProfit} {selected.value}</h3>
                        </div>
                      </div>
                      <div className="col pt-2 pe-2">
                        <div className="card p-3 card-back h-100">
                          <h5 className="hm-sec3-tx1">Yearly Profit</h5>
                          <h3 className="hm-sec3-tx2">{symbols.yearlyProfit} {selected.value}</h3>
                        </div>
                      </div>
                      <div className="col pt-2 ps-0">
                        <div className="card p-3 card-back h-100">
                          <h5 className="hm-sec3-tx1">Daily Yield</h5>
                          <h3 className="hm-sec3-tx2">+{symbols.DailyYield}%</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="home-section-four my-5 position-relative">
          <div className="container pt-lg-5">
            <div className="row text-center justify-content-center">
              <div className="col-lg-8 ">
                <h3 className="hm-tx-1 pb-lg-4">
                  Grow your crypto asset's value with willgrow
                </h3>
              </div>
            </div>
            <div className="row sec4-back-color p-lg-5 p-2 justify-content-between position-relative">
              <div className="col-lg-4 mobile-hide-sec">
                <table>
                  <h2 className="hm-sec4-tx1 p-3">Staking History</h2>
                  <tr>
                    <th>Amount</th>
                    <th>Date</th>
                  </tr>
                  <tr>
                    <td>1000 btc</td>
                    <td>01.04.2020</td>
                  </tr>
                  <tr>
                    <td>2000 btc</td>
                    <td>01.04.2020</td>
                  </tr>
                  <tr>
                    <td>3000 btc</td>
                    <td>01.04.2020</td>
                  </tr>
                  <tr>
                    <td>4000 btc</td>
                    <td>01.04.2020</td>
                  </tr>
                  <tr>
                    <td>5000 btc</td>
                    <td>01.04.2020</td>
                  </tr>
                  <tr>
                    <td>6000 btc</td>
                    <td>01.04.2020</td>
                  </tr>
                </table>
              </div>
              <div className="col-lg-4 back-sec4-top p-3 col-md-6">
                <h2 className="hm-sec4-tx1">Staking </h2>
                <div className="pt-2">
                  <h5 className="hm-sec3-tx1 opacity-100">Your wallet</h5>
                  <div className="d-flex align-items-center gap-3 p-2 sec4-btc-card">
                    <img src={btc} className="btc-sec4-img" alt="home" />
                    <h5 className="hm-sec4-tx1 mb-0">BTC</h5>
                  </div>
                </div>
                <div className="pt-3">
                  <h5 className="hm-sec3-tx1 opacity-100">Lock amount</h5>
                  <div className="d-flex align-items-center justify-content-between p-2 sec4-btc-card">
                    <h5 className="hm-sec4-tx1">100 BTC</h5>
                    <h5 className="hm-sec4-tx1">Send all</h5>
                  </div>
                  <h5 className="hm-sec3-tx1 ">
                    Stake limit: 10 BTC - 20000 BTC
                  </h5>
                </div>
                {/* <div className="pt-3">
                  <h5 className="hm-sec3-tx1 opacity-100">Validator</h5>
                  <div className="d-flex align-items-center gap-3 p-2 sec4-btc-card">
                    <img src={btc} className="btc-sec4-img" alt="home" />
                    <h5 className="hm-sec4-tx1 mb-0">BTC</h5>
                  </div>
                </div> */}
                <div className="mt-3 p-2 stack-back-sec4">
                  <div className="d-flex align-items-center justify-content-between pt-2 sec4-btc-card">
                    <h5 className="hm-sec3-tx1">Stake Date</h5>
                    <h5 className="hm-sec4-tx1">22.09.2022 03:00</h5>
                  </div>
                  <div className="d-flex align-items-center justify-content-between pt-2 sec4-btc-card">
                    <h5 className="hm-sec3-tx1">Stake Launch</h5>
                    <h5 className="hm-sec4-tx1">22.09.2022 03:00</h5>
                  </div>
                  <div className="d-flex align-items-center justify-content-between pt-2 sec4-btc-card">
                    <h5 className="hm-sec3-tx1">Stake Complete</h5>
                    <h5 className="hm-sec4-tx1">22.09.2022 03:00</h5>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between pt-3">
                  <h5 className="hm-sec4-tx1">Daily percentage</h5>
                  <h5 className="hm-sec4-tx1">10.00%</h5>
                </div>
                <div
                  class="login-btn py-lg-3 mt-3 d-flex justify-content-center"
                  type="submit"
                >
                  Stake now
                </div>
              </div>
              <div className="col-lg-6 timeline-section col-md-6">
                <div class="container ">
                  <div class="wrapper">
                    <ul class="sessions">
                      <li>
                        <div className="dsfdsfds">
                          <div class="time">
                            Grow your crypto asset's value with willgrow
                          </div>
                          <p>Willgrow supports a wide range of assets.</p>
                        </div>
                      </li>
                      <li>
                        <div className="dsfdsfds">
                          <div class="time">Stake your crypto</div>
                          <p>Lock your funds in Willgrow wallet</p>
                        </div>
                      </li>
                      <li>
                        <div className="dsfdsfds">
                          <div class="time">Start receiving rewards</div>
                          <p>
                            Earn fixed daily rewards at 10% a day through our
                            protocol
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="dsfdsfds">
                          <div class="time">Claim profit</div>
                          <p>Withdraw rewards to your wallet manually</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="home-section-five py-5">
          <div className="container">
            <div className="row text-center justify-content-center">
              <div className="col-lg-7">
                <h3 className="hm-tx-1">
                  Cryptocurrencies available for Staking
                </h3>
              </div>
            </div>
            <div className="row px-lg-5 px-2">
              <Swiper
                slidesPerView={1}
                spaceBetween={0}
                navigation={false}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                breakpoints={{
                  640: {
                    slidesPerView: 1,
                    spaceBetween: 30,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                  },
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                  },
                }}
                modules={[Autoplay, Pagination, Navigation]}
                className="py-lg-5 py-3"
              >

                {
                  coinsData?.map((coinsData) => {
                    return (<>
                    <SwiperSlide>
                      <div className="p-4">
                        <img src={coinsData?.logo} className="btc-img-sec5" alt="home" />
                        <p className="hm-sec3-tx2 pt-3">{coinsData?.name}({coinsData?.symbol})</p>
                        <div className="liveprice">
                          <h5>{coinsData?.symbol}:{coinsData?.current_price}</h5>
                        </div>
                        <div className="p-2 d-flex justify-content-between align-items-center text-start sec5-back-border">
                          <div>
                            <h5 className="hm-sec3-tx1 opacity-100">
                              First rewards in
                            </h5>
                            <h5 className="hm-sec3-tx2">24 hours</h5>
                          </div>
                          <div>
                            <CiCalendar className="sec5-cal-icon" />
                          </div>
                        </div>
                        <div className="p-2 mt-2 d-flex justify-content-between align-items-center text-start sec5-back-border">
                          <div>
                            <h5 className="hm-sec3-tx1 opacity-100">
                              Expected yearly ROI
                            </h5>
                            <h5 className="hm-sec3-tx2">10.00 %</h5>
                          </div>
                          <div>
                            <HiMiniArrowTrendingUp className="sec5-cal-icon" />
                          </div>
                        </div>
                        <button class="signup-btn py-2 mt-3" type="submit" onClick={handleStakeNowClick}>
                          Stake now
                        </button>
                      </div>
                    </SwiperSlide>
                    </>)
                  })
                }
                {/* <SwiperSlide>
                  <div className="p-4">
                    <img src={ustd} className="btc-img-sec5" alt="home" />
                    <p className="hm-sec3-tx2 pt-3">Solana(SOL)</p>
                    <div className="liveprice">
                      <h5>SOLVEUSDT:{solanaPrice}</h5>
                    </div>
                    <div className="p-2 d-flex justify-content-between align-items-center text-start sec5-back-border">
                      <div>
                        <h5 className="hm-sec3-tx1 opacity-100">
                          First rewards in
                        </h5>
                        <h5 className="hm-sec3-tx2">24 hours</h5>
                      </div>
                      <div>
                        <CiCalendar className="sec5-cal-icon" />
                      </div>
                    </div>
                    <div className="p-2 mt-2 d-flex justify-content-between align-items-center text-start sec5-back-border">
                      <div>
                        <h5 className="hm-sec3-tx1 opacity-100">
                          Expected yearly ROI
                        </h5>
                        <h5 className="hm-sec3-tx2">10.00 %</h5>
                      </div>
                      <div>
                        <HiMiniArrowTrendingUp className="sec5-cal-icon" />
                      </div>
                    </div>
                    <button class="signup-btn py-2 mt-3" type="submit" onClick={handleStakeNowClick}>
                      Stake now
                    </button>
                  </div>
                </SwiperSlide> */}

                {/* <SwiperSlide>
                  <div className="p-4">
                    <img src={btc} className="btc-img-sec5" alt="home" />
                    <p className="hm-sec3-tx2 pt-3">BTC</p>
                    <div className="liveprice">
                      <h5>BTCUSDT:{btcPrice}</h5>
                    </div>
                    <div className="p-2 d-flex justify-content-between align-items-center text-start sec5-back-border">
                      <div>
                        <h5 className="hm-sec3-tx1 opacity-100">
                          First rewards in
                        </h5>
                        <h5 className="hm-sec3-tx2">24 hours</h5>
                      </div>
                      <div>
                        <CiCalendar className="sec5-cal-icon" />
                      </div>
                    </div>
                    <div className="p-2 mt-2 d-flex justify-content-between align-items-center text-start sec5-back-border">
                      <div>
                        <h5 className="hm-sec3-tx1 opacity-100">
                          Expected yearly ROI
                        </h5>
                        <h5 className="hm-sec3-tx2">10.00 %</h5>
                      </div>
                      <div>
                        <HiMiniArrowTrendingUp className="sec5-cal-icon" />
                      </div>
                    </div>
                    <button class="signup-btn py-2 mt-3" type="submit" onClick={handleStakeNowClick}>
                      Stake now
                    </button>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="p-4">
                    <img src={ustd} className="btc-img-sec5" alt="home" />
                    <p className="hm-sec3-tx2 pt-3">USDT(TRC20)</p>
                    <div className="liveprice">
                      <h5>TRXUSDT:{trxprice}</h5>
                    </div>
                    <div className="p-2 d-flex justify-content-between align-items-center text-start sec5-back-border">
                      <div>
                        <h5 className="hm-sec3-tx1 opacity-100">
                          First rewards in
                        </h5>
                        <h5 className="hm-sec3-tx2">24 hours</h5>
                      </div>
                      <div>
                        <CiCalendar className="sec5-cal-icon" />
                      </div>
                    </div>
                    <div className="p-2 mt-2 d-flex justify-content-between align-items-center text-start sec5-back-border">
                      <div>
                        <h5 className="hm-sec3-tx1 opacity-100">
                          Expected yearly ROI
                        </h5>
                        <h5 className="hm-sec3-tx2">10.00 %</h5>
                      </div>
                      <div>
                        <HiMiniArrowTrendingUp className="sec5-cal-icon" />
                      </div>
                    </div>
                    <button class="signup-btn py-2 mt-3" type="submit" onClick={handleStakeNowClick}>
                      Stake now
                    </button>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="p-4">
                    <img src={ustd} className="btc-img-sec5" alt="home" />
                    <p className="hm-sec3-tx2 pt-3">USDT(BEP20)</p>
                    <div className="liveprice">
                      <h5>BNBUSDT:{bnbPrice}</h5>
                    </div>
                    <div className="p-2 d-flex justify-content-between align-items-center text-start sec5-back-border">
                      <div>
                        <h5 className="hm-sec3-tx1 opacity-100">
                          First rewards in
                        </h5>
                        <h5 className="hm-sec3-tx2">24 hours</h5>
                      </div>
                      <div>
                        <CiCalendar className="sec5-cal-icon" />
                      </div>
                    </div>
                    <div className="p-2 mt-2 d-flex justify-content-between align-items-center text-start sec5-back-border">
                      <div>
                        <h5 className="hm-sec3-tx1 opacity-100">
                          Expected yearly ROI
                        </h5>
                        <h5 className="hm-sec3-tx2">10.00 %</h5>
                      </div>
                      <div>
                        <HiMiniArrowTrendingUp className="sec5-cal-icon" />
                      </div>
                    </div>
                    <button class="signup-btn py-2 mt-3" type="submit" onClick={handleStakeNowClick}>
                      Stake now
                    </button>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="p-4">
                    <img src={btc} className="btc-img-sec5" alt="home" />
                    <p className="hm-sec3-tx2 pt-3">BTC</p>
                    <div className="liveprice">
                      <h5>BTCUSDT:{btcPrice}</h5>
                    </div>
                    <div className="p-2 d-flex justify-content-between align-items-center text-start sec5-back-border">
                      <div>
                        <h5 className="hm-sec3-tx1 opacity-100">
                          First rewards in
                        </h5>
                        <h5 className="hm-sec3-tx2">24 hours</h5>
                      </div>
                      <div>
                        <CiCalendar className="sec5-cal-icon" />
                      </div>
                    </div>
                    <div className="p-2 mt-2 d-flex justify-content-between align-items-center text-start sec5-back-border">
                      <div>
                        <h5 className="hm-sec3-tx1 opacity-100">
                          Expected yearly ROI
                        </h5>
                        <h5 className="hm-sec3-tx2">10.00 %</h5>
                      </div>
                      <div>
                        <HiMiniArrowTrendingUp className="sec5-cal-icon" />
                      </div>
                    </div>
                    <button class="signup-btn py-2 mt-3" type="submit" onClick={handleStakeNowClick}>
                      Stake now
                    </button>
                  </div>
                </SwiperSlide> */}

                <div className="d-flex justify-content-center mt-lg-4">
                  <button class="login-btn py-2 mt-3" type="submit">
                    Get started
                  </button>
                </div>
              </Swiper>
            </div>
          </div>
        </section>

        <section className="home-section-six mb-5">
          <div className="container">
            <div className="row sec6-back-img">
              <div className="sec6-home-back-color">
                <div className="row  py-lg-5 py-2 px-2 px-lg-5">
                  <div className="col-lg-6">
                    <h3 className="hm-tx-1 pt-3">
                      Why Stake with <br />
                      Willgrow?
                    </h3>
                  </div>
                </div>
                <div className="row row-cols-lg-2 row-cols-md-2 row-cols-1 px-lg-5 px-2 pb-4 ">
                  <div className="col">
                    <div className="">
                      <div className="d-flex align-items-center gap-2">
                        {" "}
                        <FaRegClock className="sec5-cal-icon mb-4 icon-color-back" />{" "}
                        <h3 className="hm-sec3-tx2 mb-3"> Mission</h3>{" "}
                      </div>
                      <p className="hm-sec3-tx1 opacity-100">
                        At Willgrow, we believe in empowering users to maximize
                        their crypto investments through secure and efficient
                        staking solutions. Our platform is designed to provide
                        you with the best staking opportunities, ensuring
                        transparency, reliability, and profitability.
                      </p>
                    </div>
                  </div>
                  <div className="col">
                    <div className="">
                      <div className="d-flex align-items-center gap-2">
                        {" "}
                        <FaGift className="sec5-cal-icon mb-4 icon-color-back" />{" "}
                        <h3 className="hm-sec3-tx2 mb-3">Vision</h3>{" "}
                      </div>
                      <p className="hm-sec3-tx1 opacity-100">
                        In return for staking their coins, participants are
                        rewarded with additional cryptocurrency tokens, commonly
                        referred to as staking rewards.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="home-section-seven py-lg-5 ">
          <div className="container sec6-home-back pb-2 pb-lg-0 sec-seven-back-img p-0">
            <div className="row row-cols-lg-2 row-cols-1 p-lg-5 mx-1 mx-lg-0">
              <div className="col">
                <h3 className="hm-tx-1 pt-3">How does crypto staking work?</h3>
                <p className="hm-sec3-tx1 opacity-100">
                  Cryptocurrency staking is a process that allows holders of
                  certain cryptocurrencies to earn rewards and participate in
                  the network's operations by locking up their coins in a wallet
                </p>
              </div>
              <div className="col">
                <div class="accordion" id="accordionPanelsStayOpenExample">
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button fw-bold"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseOne"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-collapseOne"
                      >
                        Locking your funds securely
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseOne"
                      class="accordion-collapse collapse show"
                    >
                      <div class="accordion-body opacity-75">
                        By participating in the staking process, you lock up
                        your crypto assets to support the blockchain network's
                        operations and security. In return, you earn additional
                        tokens, much like earning interest in a traditional
                        savings account. This steady accumulation of rewards,
                        often distributed daily, weekly, or monthly, can
                        substantially increase your holdings over time.
                        Furthermore, many staking platforms offer the option to
                        automatically reinvest your earned rewards, enabling the
                        power of compound interest to accelerate your crypto
                        growth.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion" id="accordionPanelsStayOpenExample">
                  <div class="accordion-item mt-3">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button collapsed fw-bold"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseTwo"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseTwo"
                      >
                        Rewards for your contribution
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseTwo"
                      class="accordion-collapse collapse"
                    >
                      <div class="accordion-body opacity-75">
                        Staking your cryptocurrency can significantly boost your
                        investment growth through regular rewards and the
                        compounding effect. As blockchain technology continues
                        to evolve, the opportunities for staking are expanding,
                        making it an increasingly attractive strategy for both
                        novice and experienced investors. With careful selection
                        of staking pools and awareness of the associated risks,
                        staking can be a reliable and lucrative way to enhance
                        your cryptocurrency portfolio.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
