import React from "react";
import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import { Spinner } from "react-bootstrap";
import profile3 from "../Assets/images/login-signup/profile3.png";
import render12 from "../Assets/images/login-signup/RENDER-1-copy.webp";
import { useLayoutEffect } from "react";
import Theme from "../pages/separate/Theme";
import darklogo from "../Assets/images/logodarktheme.png";
import whitelogo from "../Assets/images/logo-whitetheme.png";
import { makeApiRequest } from "../config/AxiosService";
function ForgotPassword({ setShowNavbar }) {
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    touched,
    errors,
    values,
    resetForm,
    setValues,
  } = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email("Please enter a valid email!")
        .required("E-mail is required!")
        .matches(regex, "Invalid Email!"),
    }),
    onSubmit: async (values) => {
      try {

        const payload = {
          email: values.email
        };

        let params = {
          url: `userforgotpassword`,
          method: "POST",
          data: payload,
        };
        const response = await makeApiRequest(params);

        const token = response.token

        if (token) {
          toast.success("Mail Sent!");
          setTimeout(() => navigate("/login"), 2000);
        }
        if (response.message === "User not found") {
          toast.warning("Please Enter A Valid Mail!");
          setTimeout(() => window.location.reload(), 2000);
        }
      } catch (error) {
        if (error.response) {
          console.error("Response data:", error.response);
        }
      }
    },
  });

  useLayoutEffect(() => {
    setShowNavbar(false);
  }, []);

  return (
    <div>
      <ToastContainer />

      <section className="login-bg-styling-css login-section">
        <div className="row justify-content-center align-items-center m-0">
          <div className="col-lg-11 ">
            <div className="pt-4">
              <a class="navbar-brand darklogo  ms-lg-5">
                <img src={darklogo} className="logo " alt="logo" />
              </a>
              <a class="navbar-brand whitelogo ms-lg-5">
                <img src={whitelogo} className="logo " alt="logo" />
              </a>
            </div>
            <div className="theme-fixed">
              <Theme />
            </div>
          </div>
        </div>
        <div className="container-fluid col-lg-9">
          <div className="row min-vh text-center align-items-center justify-content-between g-4">
            <div className="col-lg-5 text-center py-4 register-card-bg-styling login-inputs-css">
              <img src={profile3} className="usermask-image-css" />
              <form
                className="new-contact-form"
                name="footercontact"
                id="footercontact"
                autocomplete="off"
                novalidate="novalidate"
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                  return false;
                }}
              >
                <p className="fs-20 fw-500 text-white">Email Address</p>
                <div className="mb-3">
                  <div class="input-group mb-0">
                    <input
                      type="text"
                      name="email"
                      class="form-control mb-2"
                      id="exampleInputPassword1"
                      placeholder="Email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      onKeyPress={(event) => {
                        const keyCode = event.which || event.keyCode;
                        const keyValue = String.fromCharCode(keyCode);

                        // Allow only numeric values (0-9) and the backspace key (8)
                        if (!/^[a-zA-Z0-9._@]+$/.test(keyValue)) {
                          event.preventDefault();
                        }
                      }}
                      invalid={touched.email && errors.email ? true : false}
                    />
                  </div>
                  {touched.email && errors.email && (
                    <small className="invalid-login error password-text-33">
                      {errors.email}
                    </small>
                  )}
                </div>
                {loader == true ? (
                  <a className="forgetpassword-btn spinner-btn-1">
                    <Spinner animation="border" />
                  </a>
                ) : (
                  <button className="forgetpassword-btn spinner-btn-1 p-0">
                    {" "}
                    Forgot Password{" "}
                  </button>
                )}
              </form>
            </div>
            <div className="col-lg-6 mt-lg-5 pt-lg-5 ">
              <p className="fcaps heading-1-css  mb-2 d-lg-block d-none">
                Welcome
              </p>
              <p className="fs-20  text-center d-lg-block d-none">
                Register continue your journey with the largest community of
                innovators
              </p>
              <p className="mt-lg-5 fs-20 fw-500">Don't have an account?</p>
              <a href="/register">
                <button className="btn main-btn-css-1 ">Register</button>
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ForgotPassword;
