import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { makeApiRequest } from "../../config/AxiosService";

const ProtectedRoute = (props) => {
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(true);

    const fetchToken = async () => {
        try {
            let params = {
                url: "verify-token",
                method: "POST",

            }
            let response = await makeApiRequest(params);
            if (response.status) {

            } else {
                localStorage.clear()
                setIsLoggedIn(false);
                return window.location.href = ('/');
            }
        } catch (error) {
            console.log("error:", error.message);
        }
    }
    useEffect(() => {
        fetchToken()
    }, [])

    return (
        <React.Fragment>
            {
                isLoggedIn ? props.children : null
            }
        </React.Fragment>

    );
}
export default ProtectedRoute;