import React, { useEffect, useRef, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { FaXTwitter } from "react-icons/fa6";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { eye } from "react-icons-kit/feather/eye";
import { FaRegEyeSlash } from "react-icons/fa";
import { Icon } from "react-icons-kit";
import { Formik, useFormik } from "formik";
import { Spinner } from "react-bootstrap";
import * as Yup from "yup";
import { useLayoutEffect } from "react";
import { logDOM } from "@testing-library/react";
import render12 from "../Assets/images/login-signup/RENDER-1-copy.webp";
import darklogo from "../Assets/images/logodarktheme.png";
import whitelogo from "../Assets/images/logo-whitetheme.png";
import Theme from "../pages/separate/Theme";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { makeApiRequest } from "../config/AxiosService";

function ResetPassword({ setShowNavbar }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [mailStatus, setMailStatus] = useState({});
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(eyeOff);
  const [loader, setLoader] = useState(false);
  const [confirmType, setConfirmType] = useState("password");
  const [confirmIcon, setConfirmIcon] = useState(eyeOff);
  const [mailexpire, setMailexpire] = useState();
  const navigate = useNavigate();
  const { email, token } = useParams();
  const expCheck = useRef(null);

  const handleToggle = () => {
    if (type === "password") {
      setIcon(eye);
      setType("text");
    } else {
      setIcon(eyeOff);
      setType("password");
    }
  };
  const confirmPassHandleToggle = () => {
    if (confirmType === "password") {
      setConfirmIcon(eye);
      setConfirmType("text");
    } else {
      setConfirmIcon(eyeOff);
      setConfirmType("password");
    }
  };

  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const passwordMatch =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[~()\-_+=!@#$%^&*<>`:{}\[\];'?",.])(?=.*\d).{8,}$/;

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    touched,
    errors,
    values,
    resetForm,
    setValues,
  } = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object().shape({
      password: Yup.string()
        .required("Password is required!")
        .matches(
          passwordMatch,
          "Password must contain at least 8 characters, one uppercase letter, one number, and at least one symbol!"
        ),
      confirmPassword: Yup.string()
        .required("Confirm password is required!")
        .oneOf([Yup.ref("password"), null], "Passwords must match"),
    }),

    onSubmit: async (values) => {
      try {

        const payload = {
         password: values.password,
         confirmPassword: values.confirmPassword
        };
        let params = {
          url: `resetuserpassword/${email}`,
          method: "POST",
          data: payload,
        };
        const response = await makeApiRequest(params);

        if (response.status == true) {
          toast.success("Password Reset Successfully!");
          setTimeout(() => {
            navigate("/login");
          }, 2000);
        } else if (
          response.status == false &&
          response.message === "User not found"
        ) {
          toast.Warning("User not found");
          setTimeout(() => {
            navigate("/forgot-password");
          }, 2000);
        } else if (response.message === "Passwords do not match") {
          toast.Warning("Passwords do not match");
          setTimeout(() => {
            navigate("/forgot-password");
          }, 2000);
        }
      } catch (error) {
        console.error("Error:", error.message);
        toast.error("An error occurred. Please try again later.");
      }
    },
  });

  useEffect(() => {
    const tokenverify = () => {
      if (token) {
        const { exp } = JSON.parse(atob(token.split(".")[1]));
        const expirationTime = new Date(exp * 1000);
        if (expirationTime < new Date()) {
          toast.warning("Token Expired !");
          setTimeout(() => {
            window.location.href = "/forgot-password";
          }, 2000);
        }
      }
    };
    tokenverify();
  }, []);
  
  useLayoutEffect(() => {
    setShowNavbar(false);
}, [])


  return (
    <div className="App">
      <ToastContainer />
      <div className="container-fluid login-section register-bg-styling-css">
      <div className="col-lg-11 ">
                        <div className="pt-4">
                            <a class="navbar-brand darklogo  ms-lg-5"><img src={darklogo} className="logo " alt="logo" /></a>
                            <a class="navbar-brand whitelogo ms-lg-5"><img src={whitelogo} className="logo " alt="logo" /></a>
                        </div>
                        <div className="theme-fixed">
                            <Theme />
                        </div>
                    </div>
        <div className="custom-thankyou">

          <div className="row  align-items-center justify-content-md-end justify-content-lg-center">
            <div className="col-lg-6  col-md-7">
              <div className="custom-inside-thankyou">
                {mailexpire == false ? (
                  <h2 className="mt-4 mb-4">{"Your Mail is Expired"}</h2>
                ) : (
                  <form
                    className="new-contact-form"
                    name="footercontact"
                    id="footercontact"
                    autocomplete="off"
                    novalidate="novalidate"
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSubmit();
                      return false;
                    }}
                  >
                    <p className="fs-3 fw-500 text-white">Reset-Password</p>
                    <div className="mb-3">
                      <div className="mb-3">
                        <div class="input-group ">
                          <input
                            type={type}
                            class="form-control with-input mb-1"
                            id="exampleInputPassword1"
                            name="password"
                            maxLength="15"
                            placeholder="Password"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                            invalid={
                              touched.password && errors.password ? true : false
                            }
                          />
                          <span
                            class="input-group-text eye-icon reset-eye"
                            onClick={handleToggle}
                          >
                            <Icon
                              class="absolute mr-10"
                              icon={icon}
                              size={25}
                            />
                            {/* <FaRegEyeSlash /> */}
                          </span>
                        </div>
                        {touched.password && errors.password && (
                          <small className="invalid-login error password-text-33">
                            {errors.password}
                          </small>
                        )}
                      </div>

                      <div class="input-group mb-0 mt-2">
                        <input
                          type={confirmType}
                          class="form-control with-input mb-1"
                          id="exampleInputPassword1"
                          name="confirmPassword"
                          maxLength="15"
                          placeholder="confirmPassword"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.confirmPassword}
                          invalid={
                            touched.confirmPassword && errors.confirmPassword
                              ? true
                              : false
                          }
                        />
                        <span
                          class="input-group-text eye-icon reset-eye"
                          onClick={confirmPassHandleToggle}
                        >
                          <Icon
                            class="absolute mr-10"
                            icon={confirmIcon}
                            size={25}
                          />
                          {/* <FaRegEyeSlash /> */}
                        </span>
                      </div>
                      {touched.confirmPassword && errors.confirmPassword && (
                        <small className="invalid-login error password-text-33">
                          {errors.confirmPassword}
                        </small>
                      )}
                    </div>
                    {loader == true ? (
                      <Spinner animation="border" />
                    ) : (
                      <button className="register-btn-1 ">
                        {" "}
                        Reset Password{" "}
                      </button>
                    )}
                  </form>
                )}
                <div className="mt-3">
                  <Link to="/login" className="register-btn-1">
                    {" "}
                    login{" "}
                  </Link>
                </div>

                <div className="custom-icon-form mt-3">
                  <span>
                    <a href="" target="_blank" className="text-dark">
                      <FaXTwitter className="me-1 thankyou-icon" />
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
