import React, {  useEffect } from "react";
import $ from "jquery";
import { BsFillSunFill } from "react-icons/bs";
import { BsFillMoonFill } from "react-icons/bs";

export default function Theme() {

    useEffect(() => {
        var darkMode;
        if (localStorage.getItem("dark-mode")) {
          darkMode = localStorage.getItem("dark-mode");
        } else {
          darkMode = "light";
        }
    
        localStorage.setItem("dark-mode", darkMode);
    
        if (localStorage.getItem("dark-mode") === "dark") {
          $("body").addClass("white-theme");
          $(".dark-button").hide();
          $(".light-button").show();
        }
    
        $(".dark-button").on("click", function () {
          $(".dark-button").hide();
          $(".light-button").show();
          $("body").addClass("white-theme");
          localStorage.setItem("dark-mode", "dark");
        });
    
        $(".light-button").on("click", function () {
          $(".light-button").hide();
          $(".dark-button").show();
          $("body").removeClass("white-theme");
          localStorage.setItem("dark-mode", "light");
        });
    
        $(document).on("scroll", function () {
          if ($(document).scrollTop() > 5) {
            $(".navbar").hide();
          } else {
            $(".navbar").show();
          }
        });
      });
    
  return (
    <div>
    <button class="mode-toggler-button dark-button bg-transparent  border-0">
      <BsFillSunFill className="theme-btn" />
    </button>
    <button
      class="mode-toggler-button light-button bg-transparent border-0"
      style={{ display: "none" }}
    >
      <BsFillMoonFill className="theme-btn" />
    </button>
  </div>
  )
}
