import React, { useState, useEffect } from 'react';
import { IoIosTimer } from "react-icons/io";

const CountdownTimer = ({ initialCountdown }) => {
  const [timeLeft, setTimeLeft] = useState(initialCountdown);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft(prevTime => {
        const { days, hours, minutes, seconds } = prevTime;

        if (seconds > 0) {
          return { ...prevTime, seconds: seconds - 1 };
        }
        if (minutes > 0) {
          return { ...prevTime, minutes: minutes - 1, seconds: 59 };
        }
        if (hours > 0) {
          return { ...prevTime, hours: hours - 1, minutes: 59, seconds: 59 };
        }
        if (days > 0) {
          return { ...prevTime, days: days - 1, hours: 23, minutes: 59, seconds: 59 };
        }
        return { days: 0, hours: 0, minutes: 0, seconds: 0 };
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className='d-flex withdraw-timer align-items-center'>
      <div><IoIosTimer className='timer-icon'/></div>
      <p className='mb-0'>{`${timeLeft.days}days ${timeLeft.hours}hrs ${timeLeft.minutes}min ${timeLeft.seconds}sec`}</p>
    </div>
  );
};

export default CountdownTimer;