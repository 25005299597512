import React from 'react'
import darklogo from '../../Assets/images/logodarktheme.png'
import whitelogo from '../../Assets/images/logo-whitetheme.png'
import { FaApple } from "react-icons/fa";
import playstore from "../../Assets/images/download.png";
import ios from "../../Assets/images/ios.png";
import Apk from "../../pages/WillGrow.apk";


export default function Footer() {
  return (
   <>
   <section className='footer-section mt-4'>
    <div className='container pt-5'>
     <div className='row justify-content-center align-items-center'>
      <div className='col-lg-6 col-md-6'>
       <div>
       <a class="navbar-brand darklogo"><img src={darklogo} className="logo " alt="logo"/></a>
       <a class="navbar-brand whitelogo"><img src={whitelogo} className="logo " alt="logo"/></a>
       <p className="footer-tx1 pt-4">
       Will Grow helps you receive payments from anyone in the world.
      </p>
       </div>
      </div>
      <div className='col-lg-6 col-md-6 d-flex justify-content-lg-center justify-content-md-center' >
      <div className='d-lg-flex align-items-center justify-content-center gap-2'>
        {/* <div>
         <FaApple />
        </div>
        <div className='text-start'><h4 className="footer-tx2">
          Download</h4>
          <h3 className="footer-tx3">APP Store</h3></div> */}
           <a  href={Apk} download="WillGrow.apk" > <img src={playstore} alt='footer'/> </a>
           <a  href={Apk} download="WillGrow.apk" > <img src={ios} alt='footer'/> </a>
      </div>
      </div>
     </div>
     <div className='text-center'>
         <p className="hm-sec3-tx1 mb-0">
           Copyright @ 2024 Will Grow
        </p>
     </div>
    </div>
   </section>
   </>
  )
}
