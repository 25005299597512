import React, { useEffect, useState } from 'react'
import { ToastContainer } from 'react-toastify'
import DataTable from 'react-data-table-component';
import { makeApiRequest } from '../../config/AxiosService';
import { useNavigate } from 'react-router-dom';

function RewardHistory() {
    const navigate = useNavigate()
    const [getData, setGetData] = useState([])

    const fetchData = async () => {
        let params = {
            url: "getDepositHistory",
            method: "GET"
        }
        const response = await makeApiRequest(params)
        if (response.status == true) {
            setGetData(response.data)
        } else {
            setGetData([])
            console.log("Cannot get the Response");
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    const columns = [
        {
            name: 'S.No',
            selector: (row, index) => index + 1,
            sortable: false,
            width: '70px'
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
        },
        {
            name: 'Wallet Address',
            selector: row => row.fromAddress,
            sortable: true,
        },
        {
            name: 'Network',
            selector: row => row.network,
            sortable: true,
        },
        {
            name: 'Deposited Amount',
            selector: row => row.latestTransactionAmount,
            sortable: true,
        },
        {
            name: 'View Data',
            cell: (row) => (
                <button
                    onClick={() => handleViewClick(row)}
                    style={{
                        padding: '5px 10px',
                        backgroundColor: '#F8C702',
                        color: 'var(--color-2)',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: 'pointer'
                    }}
                >
                    View Reward
                </button>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        }
    ];

    const handleViewClick = async (row) => {
        navigate(`/dashboard/reward-data/${row._id}`, { state: { datas: row }});
    }

    return (
        <div className="App refer-section">
            <ToastContainer />
            <div className="container-fluid" style={{ marginLeft: "15px" }}>
                <div className="custom-saled-order text-dark">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="py-3 justify-content-center d-flex justify-content-between">
                                <h3 className="component-user text-center ms-lg-0">Deposit Reward History</h3>
                            </div>
                            <div className="custom-inside-saled-order text-center mt-5">
                                <div className="tab-content" id="pills-tabContent">
                                    <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabIndex="0">
                                        <div className="custom-datascroll">
                                            <DataTable
                                                columns={columns}
                                                data={getData}
                                                pagination
                                                persistTableHead
                                                paginationPerPage={5}
                                                paginationRowsPerPageOptions={[5, 10, 15, 20]}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RewardHistory